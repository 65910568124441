export const newTuoteRow = (rowNumber) => {
  return {
    rowNumber,
    id: null,
    pituus: 0,
    leveys: 0,
    korkeus: 0,
    maara: 1,
    paino: 0,
    kuvaus: '',
  }
}

const initialState = () => ({
  session: undefined, // gmapsia varten session uuid. käytetään myös logituksessa.
  timestamp: new Date().getTime(),
  allowToday: false,
  tuoteEntities: [],
  cities: [],
  pyhapaivat: {
    isPending: false,
    data: {},
  },
  stepper: {
    step: 'address',
    steps: [
      'address',
      'tuote',
      'customer',
      'checkout'
    ]
  },
  autocompleteResults: {
    from: [],
    to: [],
  },
  form: {
    // address
    from: {
      company: '',
      thoroughfare: '',
      streetNumber: '',
      postalCode: '',
      city: '',
      country: 'FI',
      contact: {
        firstname: '',
        lastname: '',
        phone: '',
        email: ''
      }
    },
    to: {
      company: '',
      thoroughfare: '',
      streetNumber: '',
      postalCode: '',
      city: '',
      country: 'FI',
      contact: {
        firstname: '',
        lastname: '',
        phone: '',
        email: ''
      }
    },
    // tuote
    tuoteRows: [
      newTuoteRow(0)
    ],
    lastTuoteRowNumber: 0,
    // customer
    customer: {
      firstname: '',
      lastname: '',
      company: '',
      businessId: '',
      phone: '',
      email: '',
      thoroughfare: '',
      postalCode: '',
      city: '',
      country: 'FI',
    },
    // TODO: Checkout ei ole enää päällä
    billingType: 'invoice', // null || 'checkout' || 'invoice'
    billingAddress: {
      type: 'einvoice', // einvoice || email || mail
      einvoiceAddress: '',
      einvoiceOperator: '',
      email: '',
      thoroughfare: '',
      postalCode: '',
      city: '',
      country: 'FI',
    },
    date: new Date().toISOString().substr(0, 10),
    info: '',
  },
  price: {
    isPending: false,
    price: 0,
    parts: {},
  },
  order: {
    isPending: false,
    data: null,
  },
  checkout: {
    paymentId: null,
    providers: [],
    terms: '',
  },
  errors: [],
})

export default initialState();
