<template>
  <button-custom
    @click="doOrder"
    color="primary"
    label="Lähetä tilaus"
    :loading="isPending"
    :disabled="isPending"
  >
  </button-custom>
</template>

<script>
import { get } from 'lodash'
import { mapActions, mapState } from 'vuex'
import ButtonCustom from './ButtonCustom'
export default {
  name: 'button-confirm-invoice-order',
  components: {
    ButtonCustom
  },
  props: {
    valid: {
      type: Boolean,
      default: true,
    },
  },
  data () {
    return {
      billingAddressIsPending: false
    }
  },
  computed: {
    ...mapState({
      orderIsPending: state => state.order.isPending
    }),
    isPending () {
      return this.orderIsPending || this.billingAddressIsPending
    }
  },
  methods: {
    ...mapActions(['setBillingAddress']),
    validate () {
      this.$eventBus.$emit('validateBillingAddress')
    },
    doOrder () {
      this.validate()
      this.$nextTick(() => {
        if (this.valid) {
          this.billingAddressIsPending = true
          this.setBillingAddress()
            .then((res) => {
              const orderId = get(res, ['order', 'id'], '')
              this.billingAddressIsPending = false
              this.$router.push(`/order/${orderId}/confirm`)
            })
            .catch(() => this.billingAddressIsPending = false)
        }
      })
    }
  }
}
</script>
