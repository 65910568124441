<template>
  <div class="tuote-rows">
    <tuote-row
      v-for="row in rows"
      :row="row"
      :key="row.rowNumber"
      :rowNumber="row.rowNumber"
      :totalMaara="totalMaara"
      :totalPaino="totalPaino"
    />
    <button-add-tuote-row :valid="valid"/>
  </div>
</template>
<script>
import TuoteRow from './TuoteRow'
import { mapState } from 'vuex'
import ButtonAddTuoteRow from './ButtonAddTuoteRow';
export default {
  name: 'tuote-rows',
  components: {
    ButtonAddTuoteRow,
    TuoteRow,
  },
  props: {
    valid: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapState({
      rows: state => state.form.tuoteRows
    }),
    totalMaara () {
      return this.rows.reduce(
        (acc, row) => acc + parseInt(row.maara, 10),
        0
      )
    },
    totalPaino () {
      return this.rows.reduce(
        (acc, row) => acc + (parseInt(row.paino, 10) * parseInt(row.maara, 10)),
        0
      )
    }
  }
}
</script>
