<template>
  <v-select
    class="city-select"
    :items="cities"
    label="Kaupunki"
    v-model="city"
    :rules="[v => !!v || 'Pakollinen tieto']"
    @change="(val) => $emit('change', val)"
  ></v-select>
</template>
<script>
import { mapState, mapActions } from 'vuex'
export default {
  name: 'address-to-city-select',
  computed: {
    ...mapState({
      cities: state => state.cities,
      selectedCity: state => state.form.to.city
    }),
    city: {
      get () {
        return this.selectedCity
      },
      set (value) {
        this.updateForm({ fieldname: 'to.city', value })
      }
    }
  },
  methods: {
    ...mapActions(['updateForm'])
  }
}
</script>
