<template>
  <v-form class="form-billing-address" ref="form" :value="value" @input="(val) => $emit('input', val)">
    <v-container>

      <v-row>
        <v-col cols="12" sm="6">
          <v-select
            class="billing-address-type-select"
            :items="billingAddressTypeOptions"
            label="Laskun tyyppi"
            v-model="billingAddressType"
            item-value="id"
            item-text="name"
            :rules="[v => !!v || 'Pakollinen tieto']"
          ></v-select>
        </v-col>
      </v-row>

      <v-row v-show="showCopyInfo">
        <v-col cols="12" sm="6">
          <button-custom classes="small gray" @click="copyInfo" label="Sama osoite kuin yrityksellä" />
        </v-col>
      </v-row>

      <template v-if="billingAddressType === 'einvoice'">
        <v-row>
          <v-col cols="12" sm="6">
             <v-text-field
               id="billing-address-einvoice-address"
               v-model="billingAddress.einvoiceAddress"
               label="Verkkolaskuosoite"
               @input="(val) => input('billingAddress.einvoiceAddress', val)"
               :rules="rules.einvoiceAddress"
             ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6">
             <v-text-field
               id="billing-address-einvoice-operator"
               v-model="billingAddress.einvoiceOperator"
               label="Välittäjä"
               @input="(val) => input('billingAddress.einvoiceOperator', val)"
               :rules="rules.einvoiceOperator"
             ></v-text-field>
          </v-col>
        </v-row>
      </template>

      <template v-if="billingAddressType === 'email'">
        <v-row>
          <v-col cols="12" sm="6">
            <v-text-field
              id="billing-address-email"
              v-model="billingAddress.email"
              label="Laskujen sähköpostiosoite"
              @input="(val) => input('billingAddress.email', val)"
              :rules="rules.email"
            ></v-text-field>
          </v-col>
        </v-row>
      </template>

      <template v-if="billingAddressType === 'mail'">
        <v-row>
          <v-col cols="12" sm="6">
            <v-text-field
              id="billing-address-thoroughfare"
              v-model="billingAddress.thoroughfare"
              label="Katuosoite"
              @input="(val) => input('billingAddress.thoroughfare', val)"
              :rules="rules.thoroughfare"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6">
            <v-text-field
              id="billing-address-postalCode"
              v-model="billingAddress.postalCode"
              label="Postinumero"
              @input="(val) => input('billingAddress.postalCode', val)"
              :rules="rules.postalCode"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6">
            <v-text-field
              id="billing-address-city"
              v-model="billingAddress.city"
              label="Kaupunki"
              @input="(val) => input('billingAddress.city', val)"
              :rules="rules.city"
            ></v-text-field>
          </v-col>
        </v-row>
      </template>

    </v-container>
  </v-form>
</template>
<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import ButtonCustom from '@/components/ButtonCustom'

export default {
  name: 'form-billing-address',
  components: {
    ButtonCustom
  },
  props: {
    // isValid
    value: {
      type: Boolean,
      default: true
    },
  },
  data () {
    const maxLength = {
      einvoiceAddress: 100,
      einvoiceOperator: 100,
      email: 200,
      thoroughfare: 50,
      postalCode: 10,
      city: 40,
      country: 2,
    }

    const ruleGenerator = (billingAddressType, fieldname) => ([
      v => {
        if (this.billingAddressType === billingAddressType) {
          return !!v || 'Pakollinen tieto'
        }
        return true
      },
      v => v.length <= maxLength[fieldname] || `Maksimipituus on ${maxLength[fieldname]} merkkiä`,
    ])

    const rules = {
      einvoiceAddress: ruleGenerator('einvoice', 'einvoiceAddress'),
      einvoiceOperator: ruleGenerator('einvoice', 'einvoiceOperator'),
      email: [
        ...ruleGenerator('email', 'email'),
        v => /\S+@\S+\.\S+/.test(v) || 'Syötä validi sähköpostiosoite',
      ],
      thoroughfare: ruleGenerator('mail', 'thoroughfare'),
      postalCode: ruleGenerator('mail', 'postalCode'),
      city: ruleGenerator('mail', 'city'),
      country: ruleGenerator('mail', 'country'),
    }

    const billingAddressTypeOptions = [
      {id: 'einvoice', name: 'Verkkolasku'},
      {id: 'email', name: 'Sähköpostilasku'},
      {id: 'mail', name: 'Paperilasku'}
    ]
    return {
      rules,
      billingAddressTypeOptions
    }
  },
  computed: {
    ...mapState({
      form: state => state.form,
      customer: state => state.form.customer,
      billingAddress: state => state.form.billingAddress,
    }),
    ...mapGetters(['isCompany']),
    billingAddressType: {
      get () {
        return this.billingAddress.type
      },
      set (val) {
        this.input('billingAddress.type', val)
      }
    },
    showCopyInfo () {
      return this.billingAddressType === 'email' ||
        this.billingAddressType === 'mail'
    }
  },
  methods: {
    ...mapActions(['updateForm']),
    input (fieldname, value) {
      this.updateForm({fieldname, value})
    },
    validate () {
      this.$refs.form.validate()
    },
    copyInfo () {
      if (this.billingAddressType === 'email') {
        this.input('billingAddress.email', this.customer.email)
      }
      if (this.billingAddressType === 'mail') {
        this.input('billingAddress.thoroughfare', this.customer.thoroughfare)
        this.input('billingAddress.postalCode', this.customer.postalCode)
        this.input('billingAddress.city', this.customer.city)
        this.input('billingAddress.country', this.customer.country)
      }
    },
  }
}
</script>
