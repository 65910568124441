<template>
  <v-tooltip
    top
    dark
    :disabled="!showTooltip"
  >
    <template v-slot:activator="{ on }">
      <v-btn
        :depressed="depressed"
        v-on="on"
        :class="classStr"
        :color="color"
        :disabled="disabled"
        @click="$emit('click')"
        :loading="loading"
        :icon="icon"
        :height="height"
        :data-track="trackerIdentifier"
      >
        <slot v-show="!icon">{{ label }}</slot>
      </v-btn>
    </template>
    <span>{{ tooltip }}</span>
  </v-tooltip>
</template>

<script>
import slugify from 'slugify'
export default {
  name: 'button-custom',
  props: {
    label: {
      type: String,
      default: 'Button',
    },
    color: {
      type: String,
      default: 'primary',
    },
    showTooltip: {
      type: Boolean,
      default: false,
    },
    tooltip: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: Boolean,
      default: false,
    },
    classes: {
      type: [Array, String],
      default: ''
    },
    floatRight: {
      type: Boolean,
      default: false,
    },
    trackerIdentifier: {
      type: String
    }
  },
  data () {
    let height = '40px'
    let depressed = true
    if (this.icon) {
      height = undefined
      depressed = false
    }
    return {
      height,
      depressed
    }
  },
  computed: {
    classStr () {
      let classes = [
        'button-custom',
        'button-' + slugify(this.label, '-').toLowerCase(),
        ...(Array.isArray(this.classes) ? this.classes : this.classes.split(' ')),
      ]
      if (this.icon) {
        classes.push('no-skew')
      }
      if (this.floatRight) {
        classes.push('float-right');
      }
      return classes.join(' ')
    }
  }
}
</script>
