<template>
  <v-form class="form-tuote" autocomplete="off" ref="form" :value="value" @input="(val) => $emit('input', val)">
    <v-container>
      <v-row class="header">
        <h3 class="header">Tuotteet</h3>
      </v-row>
      <tuote-rows :valid="value" />
    </v-container>
  </v-form>
</template>
<script>
import TuoteRows from './TuoteRows'

export default {
  name: 'form-tuote',
  components: {
    TuoteRows
  },
  props: {
    value: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    validate () {
      this.$refs.form.validate()
    }
  }
}
</script>
