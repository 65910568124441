<template>
  <v-app>
    <v-content>
      <div id="top">
        <v-alert
          type="error"
          v-for="error in errors"
          :key="error.id"
          :dismissible="true"
        >
          {{ error.message }} {{error.status !== 400 ? `(${error.status})` : ''}}
        </v-alert>
      </div>
      <router-view></router-view>
    </v-content>
  </v-app>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'App',
  computed: {
    ...mapState({
      errors: (state) => {
        return state.errors
      }
    })
  }
};
</script>
