<template>
  <v-form class="form-address" autocomplete="off" ref="form" :value="value" @input="(val) => $emit('input', val)">
    <v-container>


      <v-row class="justify-center">
        <v-col cols="12">
          <h3 class="header no-uppercase">Mistä noudamme</h3>
        </v-col>
      </v-row>

      <v-row class="justify-center">
        <address-from @resetValidation="resetValidation"/>
      </v-row>

      <v-row class="justify-center">
        <v-col cols="12">
          <h3 class="header no-uppercase">Mihin toimitamme</h3>
        </v-col>
      </v-row>

      <v-row class="justify-center">
        <address-to @resetValidation="resetValidation"/>
      </v-row>
    </v-container>
  </v-form>
</template>
<script>
import AddressFrom from './AddressFrom'
import AddressTo from './AddressTo'

export default {
  name: 'form-address',
  components: {
    AddressFrom,
    AddressTo,
  },
  props: {
    value: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    validate () {
      this.$refs.form.validate()
    },
    resetValidation () {
      this.$refs.form.resetValidation()
    }
  }
}
</script>
