<template>
  <v-form class="form-customer" ref="form" :value="value" @input="(val) => $emit('input', val)">
    <v-container>
      <v-row>
        <v-col cols="12">
          <button-custom classes="small gray" @click="copyInfo('from')" label="Samat kuin nouto" />
          <button-custom classes="small gray" @click="copyInfo('to')" label="Samat kuin toimitus" />
        </v-col>
      </v-row>

      <v-row>
        <v-col
          cols="12"
          sm="4"
        >
          <v-text-field
            id="customer-company"
            v-model="customer.company"
            label="Yritys"
            required
            @input="(val) => input('customer.company', val)"
            :rules="rules.company"
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
          sm="4"
        >
          <v-text-field
            id="customer-business-id"
            v-model="customer.businessId"
            label="Y-tunnus"
            required
            @input="(val) => input('customer.businessId', val)"
            :rules="rules.businessId"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col
          cols="12"
          sm="4"
        >
          <v-text-field
            id="customer-firstname"
            v-model="customer.firstname"
            label="Etunimi"
            required
            @input="(val) => input('customer.firstname', val)"
            :rules="rules.firstname"
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
          sm="4"
        >
          <v-text-field
            id="customer-lastname"
            v-model="customer.lastname"
            label="Sukunimi"
            required
            @input="(val) => input('customer.lastname', val)"
            :rules="rules.lastname"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col
          cols="12"
          sm="4"
        >
          <v-text-field
            id="customer-phone"
            v-model="customer.phone"
            label="Puhelin"
            required
            @input="(val) => input('customer.phone', val)"
            :rules="rules.phone"
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
          sm="4"
        >
          <v-text-field
            id="customer-email"
            v-model="customer.email"
            label="Sähköposti"
            required
            @input="(val) => input('customer.email', val)"
            :rules="rules.email"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col
          cols="12"
          sm="4"
        >
          <v-text-field
            id="customer-thoroughfare"
            v-model="customer.thoroughfare"
            label="Katuosoite"
            required
            @input="(val) => input('customer.thoroughfare', val)"
            :rules="rules.thoroughfare"
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
          sm="4"
        >
          <v-text-field
            id="customer-postalcode"
            v-model="customer.postalCode"
            label="Postinumero"
            required
            @input="(val) => input('customer.postalCode', val)"
            :rules="rules.postalCode"
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
          sm="4"
        >
          <v-text-field
            id="customer-city"
            v-model="customer.city"
            label="Kaupunki"
            required
            @input="(val) => input('customer.city', val)"
            :rules="rules.city"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col
          cols="12"
        >
          <v-checkbox
            v-model="deliveryTermsAccepted"
            :rules="rules.deliveryTermsAccepted"
            required
          >
            <template v-slot:label>
              <div>
                Hyväksyn
                <a v-on:click.stop.prevent="showDialogDeliveryTerms = !showDialogDeliveryTerms">
                  tilaus- ja toimitusehdot
                </a>
              </div>
            </template>
          </v-checkbox>
          <dialog-delivery-terms
            v-model="showDialogDeliveryTerms"
            @accept="deliveryTermsAccepted = true"
          />

<!--          <v-checkbox-->
<!--            class="mt-0"-->
<!--            v-model="paymentTermsAccepted"-->
<!--            :rules="rules.paymentTermsAccepted"-->
<!--            required-->
<!--          >-->
<!--            <template v-slot:label>-->
<!--              <div>-->
<!--                Hyväksyn-->
<!--                <a @click.stop href="https://www.checkout.fi/ehdot-ja-sopimukset/maksuehdot" target="_blank">-->
<!--                  maksuehdot <v-icon style="text-decoration: none;" color="primary">mdi-open-in-new</v-icon>-->
<!--                </a>-->
<!--              </div>-->
<!--            </template>-->
<!--          </v-checkbox>-->

<!--          <v-checkbox-->
<!--            class="mt-0"-->
<!--            v-model="privacyStatementAccepted"-->
<!--            :rules="rules.privacyStatementAccepted"-->
<!--            required-->
<!--          >-->
<!--            <template v-slot:label>-->
<!--              <div>-->
<!--                Hyväksyn-->
<!--                <a @click.stop href="/tietosuojaseloste.php" target="_blank">-->
<!--                  tietojeni käsittelyn <v-icon style="text-decoration: none;" color="primary">mdi-open-in-new</v-icon>-->
<!--                </a>-->
<!--              </div>-->
<!--            </template>-->
<!--          </v-checkbox>-->


        </v-col>
      </v-row>

      <v-row>
        <v-col
          cols="12"
        >
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import ButtonCustom from '@/components/ButtonCustom'
import DialogDeliveryTerms from "@/components/DialogDeliveryTerms";

export default {
  name: 'form-customer',
  components: {
    ButtonCustom,
    DialogDeliveryTerms
  },
  props: {
    value: {
      type: Boolean,
      default: true
    },
  },
  data () {
    const maxLength = {
      firstname: 30,
      lastname: 30,
      company: 50,
      businessId: 10,
      phone: 30,
      email: 50,
      thoroughfare: 50,
      postalCode: 10,
      city: 40,
      country: 2,
      info: 255,
    }

    const rules = {
      firstname: [
        v => !!v || 'Pakollinen tieto',
        v => v.length <= maxLength.firstname || `Maksimipituus on ${maxLength.firstname} merkkiä`,
      ],
      lastname: [
        v => !!v || 'Pakollinen tieto',
        v => v.length <= maxLength.lastname || `Maksimipituus on ${maxLength.lastname} merkkiä`,
      ],
      company: [
        v => !!v || 'Pakollinen tieto',
        v => v.length <= maxLength.company || `Maksimipituus on ${maxLength.company} merkkiä`,
        // v => {
        //   if (this.form.customer.businessId.length > 0 && v.length === 0) {
        //     return `Yrityksen nimi on pakollinen tieto yritysasiakkaille`
        //   }
        //   return true
        // },
      ],
      businessId: [
        v => !!v || 'Pakollinen tieto',
        v => v.length <= maxLength.businessId || `Maksimipituus on ${maxLength.businessId} merkkiä`,
        // v => {
        //   if (this.form.customer.company.length > 0 && v.length === 0) {
        //     return `Y-tunnus on pakollinen tieto yritysasiakkaille`
        //   }
        //   return true
        // },
      ],
      phone: [
        v => !!v || 'Pakollinen tieto',
        v => v.length <= maxLength.phone || `Maksimipituus on ${maxLength.phone} merkkiä`,
        v => /^[0-9+]+$/.test(v) || 'Arvo voi sisältää ainoastaan numeroita (0-9) sekä plus-merkin maakoodissa (+)',
      ],
      email: [
        v => !!v || 'Pakollinen tieto',
        v => v.length <= maxLength.email || `Maksimipituus on ${maxLength.email} merkkiä`,
        v => /\S+@\S+\.\S+/.test(v) || 'Syötä validi sähköpostiosoite',
      ],
      thoroughfare: [
        v => !!v || 'Pakollinen tieto',
        v => v.length <= maxLength.thoroughfare || `Maksimipituus on ${maxLength.thoroughfare} merkkiä`,
      ],
      postalCode: [
        v => !!v || 'Pakollinen tieto',
        v => v.length <= maxLength.postalCode || `Maksimipituus on ${maxLength.postalCode} merkkiä`,
      ],
      city: [
        v => !!v || 'Pakollinen tieto',
        v => v.length <= maxLength.city || `Maksimipituus on ${maxLength.city} merkkiä`,
      ],
      country: [
        v => !!v || 'Pakollinen tieto',
        v => v.length <= maxLength.country || `Maksimipituus on ${maxLength.country} merkkiä`,
      ],

      deliveryTermsAccepted: [
        v => !!v || 'Sinun on hyväksyttävä tilaus- ja toimitusehdot',
      ],
      // paymentTermsAccepted: [
      //   v => !!v || 'Sinun on hyväksyttävä maksuehdot',
      // ],
      // privacyStatementAccepted: [
      //   v => !!v || 'Sinun on hyväksyttävä henkilökohtaisten tietojesi käsittelyn',
      // ],
    }
    return {
      rules,
      showDialogDeliveryTerms: false,
      deliveryTermsAccepted: false,
      // paymentTermsAccepted: false,
      // privacyStatementAccepted: false,
    }
  },
  watch: {
    deliveryTermsAccepted (val) {
      if (val) {
        this.$eventBus.$emit('validateAll')
      }
    }
  },
  computed: {
    ...mapState({
      form: state => state.form,
      customer: state => state.form.customer,
    }),
  },
  methods: {
    ...mapActions(['updateForm']),
    input (fieldname, value) {
      this.updateForm({fieldname, value})
    },
    validate () {
      this.$refs.form.validate()
    },
    copyInfo (source) {
      const values = {
        // customer.businessId: '',
        'customer.company': this.form[source].company,
        'customer.thoroughfare': this.form[source].thoroughfare
          + ' '
          + this.form[source].streetNumber,
        'customer.postalCode': this.form[source].postalCode,
        'customer.city': this.form[source].city,
        'customer.country': this.form[source].country,
        'customer.firstname': this.form[source].contact.firstname,
        'customer.lastname': this.form[source].contact.lastname,
        'customer.phone': this.form[source].contact.phone,
        // 'customer.email': '',
      }

      Object.keys(values).forEach((key) => {
        this.input(key, values[key])
      })
    }
  }
}
</script>
