<template>
  <v-autocomplete
    required
    v-model="select"
    :loading="isPending"
    :items="items"
    :search-input.sync="searchVal"
    hide-details
    label="Katu"
    :placeholder="placeholder"
    :rules="rules"
    :disabled="disabled"
    :readonly="readonly"
    :hide-no-data="hideNoData"
    no-data-text="- Ei tuloksia -"
    @change="onChange"
  ></v-autocomplete>
</template>

<script>
import isNil from 'lodash/isNil'
import { mapGetters } from 'vuex'
export default {
  name: 'address-autocomplete',
  props: {
    type: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: 'Katu'
    },
    value: {
      type: String,
      required: false,
    },
    rules: {
      type: Array,
      default: () => [],
    },
    city: String,
    placeholder: String,
    readonly: Boolean,
    disabled: Boolean
  },
  data () {
    return {
      searchTimeout: undefined,
      isPending: false,
      select: isNil(this.value) ? '' : this.value,
      hideNoData: true,
    }
  },
  watch: {
    searchVal: {
      immediate: true,
      handler (val) {
        // Haun ajaksi laitetaan 'Ei tuloksia' piiloon,
        // se avataan uudestaan search-metodissa
        this.hideNoData = true
        // Oltava valinnasta eroava arvo
        if (val && val.length >= 2 && val !== this.select) {
          // Jos odottelu on käynnissä, tyhjennetään se
          if (this.searchTimeout !== undefined) {
            clearTimeout(this.searchTimeout)
            this.searchTimeout = undefined
          }
          // Odotetaan hetki ennen kyselyn lähettämistä
          const waitTime = 250
          this.searchTimeout = setTimeout(() => this.search(val), waitTime)
        }
      }
    }
  },
  computed: {
    ...mapGetters(['getAutocompleteResults']),
    items () {
      return this.getAutocompleteResults(this.type)
        .map(result => result.main_text)
    },
    searchVal: {
      get () {
        return isNil(this.value) ? '' : this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    search () {
      this.isPending = true
      this.$store.dispatch(
        'autocompleteAddress',
        {
          key: this.type,
          thoroughfare: this.searchVal,
          city: this.city,
        }
      )
        .then(() => {
          // Vasta nexttick, jotta kaikki on rendattu
          this.$nextTick(() => {
            // Näytetään 'Ei tuloksia', jos ei tuloksia
            this.hideNoData = false
            this.isPending = false
          })
        })
        .catch(() => this.isPending = false)

    },
    onChange (val) {
      this.searchVal = val
      this.$nextTick(() => {
        this.$emit('selected', val)
      })
    }
  },
}
</script>
