<template>
  <button-custom
    :classes="classes"
    @click="validateAndChangeStep"
    color="primary"
    :disabled="disabled"
    :label="label"
    :floatRight="floatRight"
  ></button-custom>
</template>
<script>
import ButtonCustom from './ButtonCustom'
import { mapActions } from 'vuex'
export default {
  name: 'button-change-step',
  components: {
    ButtonCustom
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    step: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    validations: {
      type: [String, Array],
      required: false,
    },
    valid: {
      type: Boolean,
      default: true
    },
    small: {
      type: Boolean,
      default: false
    },
    floatRight: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      classes: this.small ? 'small' : ''
    }
  },
  methods: {
    ...mapActions(['goToStep', 'nextStep', 'prevStep']),
    validateAndChangeStep () {
      if (this.validations !== undefined) {
        this.validate()
        this.$nextTick(() => {
          if (this.valid) {
            this.changeStep()
          }
        })
      }
      else {
        this.changeStep()
      }
    },
    validate () {
      let validations = this.validations
      if (!Array.isArray(validations)) {
        validations = [validations]
      }
      validations.forEach((validation) => {
        this.$eventBus.$emit(validation)
      })
    },
    changeStep () {
      if (this.step === 'next') {
        this.nextStep()
      }
      else if (this.step === 'prev') {
        this.prevStep()
      }
      else {
        this.goToStep(this.step)
      }
    }
  }
}
</script>
