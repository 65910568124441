import Qs from 'qs'
import Vue from 'vue'

const request = ({method, url, params, headers, data, additionalArguments}) => {
  method = (method !== undefined) ? method : 'GET'
  url = (url !== undefined) ? url : '/'
  headers = (headers !== undefined) ? headers : {}
  data = (data !== undefined) ? data : {}
  params = (params !== undefined) ? params : {}
  additionalArguments = (additionalArguments !== undefined) ? additionalArguments : {}

  if (process.env.VUE_APP_DEBUG === '1') {
    params = {...params, XDEBUG_SESSION_START: 'PHPSTORM'}
  }

  const axiosConfig = Object.assign(
    {},
    {
      method,
      url,
      params,
      headers: Object.assign(
        {},
        {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        headers
      ),
      data,
      paramsSerializer: p => (Qs.stringify(p, { arrayFormat: 'brackets' }))
    },
    additionalArguments
  )

  return new Promise((resolve, reject) => {
    Vue.axios(axiosConfig)
      .then((res) => {
        resolve(res)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export default {
  loadApp () {
    return request({
      method: 'GET',
      url: '/api/hintalaskuri'
    })
  },
  loadPyhapaivat (query) {
    return request({
      method: 'GET',
      url: '/api/pyhapaivat',
      params: query
    })
  },
  autocompleteAddress (payload) {
    return request({
      method: 'POST',
      url: '/api/autocomplete/address',
      data: payload
    })
  },
  calculatePrice (payload) {
    return request({
      method: 'POST',
      url: '/api/hintalaskuri',
      data: {operation: 'calculate', data: payload}
    })
  },
  placeOrder (payload) {
    return request({
      method: 'POST',
      url: '/api/hintalaskuri',
      data: {operation: 'placeOrder', data: payload}
    })
  },
  setBillingAddress (payload) {
    return request({
      method: 'POST',
      url: '/api/hintalaskuri',
      data: {operation: 'setBillingAddress', data: payload}
    })
  },
  cancelOrder (payload) {
    return request({
      method: 'POST',
      url: '/api/hintalaskuri',
      data: {operation: 'cancelOrder', data: payload}
    })
  },
  confirmOrder (payload) {
    return request({
      method: 'POST',
      url: '/api/hintalaskuri',
      data: {operation: 'confirmOrder', data: payload}
    })
  },
  testSimpleCalculation (payload) {
    return request({
      method: 'POST',
      url: '/api/hintalaskuri/calculate',
      data: payload
    })
  }
}
