<template>
  <v-container>
    <spinner v-if="isPending"></spinner>
    <template v-else>
      <v-row v-if="tilaus !== null" class="mt-8 justify-center">
        <v-col cols="12" class="text-center">
          <h1>Kiitos tilauksestasi!</h1>
        </v-col>

        <v-col cols="12" class="text-center">
          <p>
            Alla näet tilauksesi tiedot.
            Tilausvahvistus toimitetaan osoitteeseen {{ yhteyshenkilo.email }}.
          </p>
          <p></p>
        </v-col>

        <v-col cols="12">
          <order-summary :showLaskutustiedot="showLaskutustiedot"/>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col cols="12" class="text-center">
          <h1>Tapahtui virhe</h1>
        </v-col>
        <v-col cols="12" class="text-center">
          <p>
            Ota yhteyttä puhelimitse.
          </p>
          <p></p>
        </v-col>
      </v-row>

      <v-row class="mt-8 justify-center">
        <v-col cols="12 text-center">
          <button-custom label="Tilaa uudelleen" @click="newOrder"/>
        </v-col>
      </v-row>
    </template>
  </v-container>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import OrderSummary from '@/components/OrderSummary'
import ButtonCustom from '@/components/ButtonCustom'
import Spinner from '@/components/Spinner'
import get from 'lodash/get'
export default {
  name: 'page-order-confirmed',
  components: {
    ButtonCustom,
    OrderSummary,
    Spinner,
  },
  data () {
    const { orderId, paymentId } = this.$route.params

    // checkout || invoice
    const billingType = this.$router.currentRoute.name === 'invoice-order-confirmed'
      ? 'invoice'
      : 'checkout'

    return {
      isPending: true,
      orderId,
      paymentId,
      billingType,
      showLaskutustiedot: billingType === 'invoice',
      checkout: this.$route.query
    }
  },
  mounted () {
    this.scrollToTop()

    // Huudetaan analyticsille
    if (typeof mek_track === 'function') {
      // eslint-disable-next-line no-undef
      mek_track('rahtilaskuri', "'Kiitos tilauksestasi'-sivu ladattu")
    }

    this.isPending = true
    this.confirmOrder({
      orderId: this.orderId,
      paymentId: this.paymentId,
      checkout: this.checkout,
      billingType: this.billingType
    })
      .then(() => {
        if (typeof gtag === 'function') {
          gtag('event', 'conversion', { 'send_to': 'AW-665002133/RYuxCMTSrs0BEJXBjL0C', 'transaction_id': this.orderId });
        }

        this.resetForm()
        this.isPending = false
      })
      .catch(() => this.isPending = false)
  },
  computed: {
    ...mapState({
      tilaus: state => state.order.data,
      session: state => state.session
    }),
    asiakas () {
      return get(this.tilaus, ['asiakas', 0])
    },
    yhteyshenkilo () {
      const yht = get(this.asiakas, 'yhteyshenkilo', [])
        .filter((yh) => yh.tyyppi === 'asiakas')
      let yh = get(yht, '0')
      return {
        etunimi: get(yh, 'etunimi', '-'),
        sukunimi: get(yh, 'sukunimi', '-'),
        email: get(yh, 'email', '-'),
        puhelin: get(yh, 'puhelin', '-'),
      }
    }
  },
  methods: {
    ...mapActions(['confirmOrder', 'resetApp', 'resetForm', 'scrollToTop']),
    newOrder () {
      this.resetApp()
      this.$router.replace({name: 'calculator'})
    }
  }
}
</script>
