<template>
  <button-custom
    classes="small"
    @click="add"
    :disabled="disabled"
    color="primary"
    label="Lisää tuote"
    :floatRight="floatRight"
  >
  </button-custom>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import ButtonCustom from './ButtonCustom'
export default {
  name: 'button-add-tuote-row',
  components: {ButtonCustom},
  props: {
    valid: {
      type: Boolean,
      default: true,
    },
    floatRight: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState ({
      noTuote (state) {
        return state.form.tuoteRows.some((row) => row.id === null)
      },
      maxCount (state) {
        return state.form.tuoteRows.length >= 5
      },
    }),
    disabled () {
      return this.noTuote || this.maxCount
    }
  },
  methods: {
    ...mapActions(['addTuoteRow']),
    add () {
      if (this.disabled) {
        return
      }
      this.$eventBus.$emit('validateTuoteRows')
      this.$nextTick(() => {
        if (this.valid) {
          this.addTuoteRow()
        }
      })
    }
  }
}
</script>
