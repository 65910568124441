<template>
  <fragment>

    <v-col
      cols="12"
      sm="6"
      md="3"
    >
      <address-to-city-select
        id="to-city"
        @change="cityChange"
      />
    </v-col>

    <v-col
      cols="12"
      sm="6"
      md="3"
    >
      <address-autocomplete
        id="to-thoroughfare"
        type="to"
        v-model="form.to.thoroughfare"
        :city="form.to.city"
        label="Katu"
        required
        @input="(val) => input('to.thoroughfare', val)"
        :rules="rules.thoroughfare"
        :readonly="disableThoroughfare"
        :disabled="disableThoroughfare"
        @selected="predictPostalCode"
      ></address-autocomplete>
    </v-col>

    <v-col
      cols="12"
      sm="6"
      md="3"
    >
      <v-text-field
        id="to-streetnumber"
        v-model="form.to.streetNumber"
        label="Talon numero, rappu ja ovi"
        required
        @input="(val) => input('to.streetNumber', val)"
        :rules="rules.streetNumber"
        :readonly="disableStreetnumber"
        :disabled="disableStreetnumber"
      ></v-text-field>
    </v-col>


    <v-col
      cols="12"
      sm="6"
      md="3"
    >
      <v-text-field
        id="to-postalcode"
        v-model="form.to.postalCode"
        label="Postinumero"
        required
        @input="(val) => input('to.postalCode', val)"
        :rules="rules.postalCode"
        :loading="postalCodeIsPending"
        :readonly="true"
        :disabled="true"
      ></v-text-field>
    </v-col>
  </fragment>
</template>
<script>
import AddressAutocomplete from './AddressAutocomplete'
import { mapActions, mapGetters } from 'vuex'
import isEmpty from 'lodash/isEmpty'
import AddressToCitySelect from './AddressToCitySelect'
import get from "lodash/get";

export default {
  name: 'address-to',
  components: {
    AddressAutocomplete,
    AddressToCitySelect,
  },
  data () {
    const maxLength = {
      streetNumber: 10,
      thoroughfare: 40,
      postalCode: 10,
      city: 40,
    }

    const rules = {
      thoroughfare: [
        v => !!v || 'Pakollinen tieto',
        v => v.length <= maxLength.thoroughfare || `Maksimipituus on ${maxLength.thoroughfare} merkkiä`,
      ],
      postalCode: [
        v => !!v || 'Pakollinen tieto',
        v => v.length <= maxLength.thoroughfare || `Maksimipituus on ${maxLength.postalCode} merkkiä`,
      ],
      city: [
        v => !!v || 'Pakollinen tieto',
        v => v.length <= maxLength.city || `Maksimipituus on ${maxLength.city} merkkiä`,
      ],
      streetNumber: [
        v => !!v || 'Pakollinen tieto',
        v => v.length <= maxLength.streetNumber || `Maksimipituus on ${maxLength.streetNumber} merkkiä`,
      ]
    }

    return {
      postalCodePredictionTimeout: undefined,
      postalCodeIsPending: false,
      rules
    }
  },
  computed: {
    ...mapGetters(['form']),
    disableThoroughfare () {
      return isEmpty(this.form.to.city)
    },
    disableStreetnumber () {
      return this.disableThoroughfare || isEmpty(this.form.to.thoroughfare)
    },
    disablePostalCode () {
      return this.disableStreetnumber || isEmpty(this.form.to.streetNumber)
    },
  },
  methods: {
    ...mapActions(['updateForm', 'resetFormAddress', 'resetAutocompleteAddress']),
    input (fieldname, value) {
      this.updateForm({fieldname, value})

      if (fieldname === 'to.streetNumber') {
        this.delayPredictPostalCode()
      }
    },
    cityChange () {
      this.resetFormAddress('to')
      this.resetAutocompleteAddress('to')
    },
    delayPredictPostalCode() {
      // Jos odottelu on käynnissä, tyhjennetään se
      if (this.postalCodePredictionTimeout !== undefined) {
        clearTimeout(this.postalCodePredictionTimeout)
        this.postalCodePredictionTimeout = undefined
      }
      // Odotetaan hetki ennen kyselyn lähettämistä
      const waitTime = 500
      this.postalCodePredictionTimeout = setTimeout(() => this.predictPostalCode(), waitTime)
    },
    predictPostalCode() {
      if (
        !isEmpty(this.form.to.city) &&
        !isEmpty(this.form.to.thoroughfare) &&
        !isEmpty(this.form.to.streetNumber)
      ) {
        this.postalCodeIsPending = true
        this.$store.dispatch(
          'autocompletePostalcode',
          {
            key: 'to-postalcode',
            thoroughfare: this.form.to.thoroughfare,
            streetNumber: this.form.to.streetNumber,
            city: this.form.to.city,
          }
        ).then((res) => {
          this.postalCodeIsPending = false
          const postalcode = get(res, '0', '')
          this.input('to.postalCode', postalcode)
        })
        .catch(() => {
          this.postalCodeIsPending = false
        })
      }
    }
  }
}
</script>
