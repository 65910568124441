<template>
  <v-row class="justify-center pt-12">
    <v-col cols="12" class="align-self-center text-center">
      <v-progress-circular
        :size="100"
        :width="5"
        color="primary"
        indeterminate
      />
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: 'spinner'
}
</script>
