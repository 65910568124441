<template>
  <v-form class="form-order" ref="form" :value="value" @input="(val) => $emit('input', val)">
    <v-container>

      <v-row>
        <v-col cols="12" sm="6">
          <v-row>
            <v-col cols="12">
              <h3 class="header text-upper">Nouto</h3>
              {{ noutoOsoite }}
            </v-col>

            <v-col
              cols="12"
              md="9"
            >
              <v-text-field
                id="from-company"
                v-model="form.from.company"
                label="Yritys"
                @input="(val) => input('from.company', val)"
                :rules="rules.company"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col
              cols="12"
              md="9"
            >
              <h4 class="header">Yhteyshenkilö</h4>
            </v-col>
            <v-col
              cols="12"
              md="9"
            >
              <v-text-field
                id="from-firstname"
                v-model="form.from.contact.firstname"
                label="Etunimi"
                required
                @input="(val) => input('from.contact.firstname', val)"
                :rules="rules.firstname"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="9"
            >
              <v-text-field
                id="from-lastname"
                v-model="form.from.contact.lastname"
                label="Sukunimi"
                required
                @input="(val) => input('from.contact.lastname', val)"
                :rules="rules.lastname"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="9"
            >
              <v-text-field
                id="from-phone"
                v-model="form.from.contact.phone"
                label="Puhelin"
                required
                @input="(val) => input('from.contact.phone', val)"
                :rules="rules.phone"
              ></v-text-field>
            </v-col>
            <!--<v-col-->
              <!--cols="12"-->
              <!--md="6"-->
            <!--&gt;-->
              <!--<v-text-field-->
                <!--id="from-email"-->
                <!--v-model="from.email"-->
                <!--label="Sähköposti"-->
                <!--required-->
                <!--@input="(val) => input('from.contact.email', val)"-->
                <!--:rules="rules.email"-->
              <!--&gt;</v-text-field>-->
            <!--</v-col>-->      </v-row>

        </v-col>

        <v-col cols="12" sm="6">
          <v-row>
            <v-col cols="12">
              <h3 class="header text-upper">Toimitus</h3>
              {{ toimitusOsoite }}
            </v-col>
            <v-col
              cols="12"
              md="9"
            >
              <v-text-field
                id="to-company"
                v-model="form.to.company"
                label="Yritys"
                @input="(val) => input('to.company', val)"
                :rules="rules.company"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col
              cols="12"
              md="9"
            >
              <h4 class="header">Yhteyshenkilö</h4>
            </v-col>

            <v-col
              cols="12"
              md="9"
            >
              <v-text-field
                id="to-firstname"
                v-model="form.to.contact.firstname"
                label="Etunimi"
                required
                @input="(val) => input('to.contact.firstname', val)"
                :rules="rules.firstname"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="9"
            >
              <v-text-field
                id="to-lastname"
                v-model="form.to.contact.lastname"
                label="Sukunimi"
                required
                @input="(val) => input('to.contact.lastname', val)"
                :rules="rules.lastname"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="9"
            >
              <v-text-field
                id="to-phone"
                v-model="form.to.contact.phone"
                label="Puhelin"
                required
                @input="(val) => input('to.contact.phone', val)"
                :rules="rules.phone"
              ></v-text-field>
            </v-col>
            <!--<v-col-->
            <!--cols="12"-->
            <!--md="6"-->
            <!--&gt;-->
            <!--<v-text-field-->
            <!--id="to-email"-->
            <!--v-model="form.to.contact.email"-->
            <!--label="Sähköposti"-->
            <!--required-->
            <!--@input="(val) => input('to.contact.email', val)"-->
            <!--:rules="rules.email"-->
            <!--&gt;</v-text-field>-->
            <!--</v-col>-->
          </v-row>
        </v-col>
      </v-row>


      <v-row>
        <v-col cols="12">
          <h4 class="header">Lisätiedot</h4>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          sm="3"
        >
          <datepicker id="date" />
        </v-col>

        <v-col
          cols="12"
          sm="8"
        >
          <v-textarea
            id="order-info"
            v-model="form.info"
            label="Toimituksen lisätiedot"
            @input="(val) => input('info', val)"
            :rules="rules.info"
            auto-grow
            :counter="255"
            rows="1"
          ></v-textarea>
        </v-col>
      </v-row>

    </v-container>
  </v-form>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import Datepicker from './Datepicker'
import { mapGetters } from 'vuex'

export default {
  name: 'form-order',
  components: {
    Datepicker,
  },
  props: {
    value: {
      type: Boolean,
      default: true
    },
  },
  data () {
    const maxLength = {
      firstname: 30,
      lastname: 30,
      phone: 30,
      email: 50,
      info: 255,
      company: 50,
    }

    const rules = {
      firstname: [
        v => !!v || 'Pakollinen tieto',
        v => v.length <= maxLength.firstname || `Maksimipituus on ${maxLength.firstname} merkkiä`,
      ],
      lastname: [
        v => !!v || 'Pakollinen tieto',
        v => v.length <= maxLength.lastname || `Maksimipituus on ${maxLength.lastname} merkkiä`,
      ],
      phone: [
        v => !!v || 'Pakollinen tieto',
        v => v.length <= maxLength.phone || `Maksimipituus on ${maxLength.phone} merkkiä`,
        v => /^[0-9+]+$/.test(v) || 'Arvo voi sisältää ainoastaan numeroita (0-9) sekä plus-merkin maakoodissa (+)',
      ],
      // email: [
      //   v => !!v || 'Pakollinen tieto',
      //   v => v.length <= maxLength.email || `Maksimipituus on ${maxLength.email} merkkiä`,
      //   v => /\S+@\S+\.\S+/.test(v) || 'Syötä validi sähköpostiosoite',
      // ],
      info: [
        // v => !!v || 'Pakollinen tieto',
        v => v.length <= maxLength.info || `Maksimipituus on ${maxLength.info} merkkiä`
      ],
      company: [
        v => v.length <= maxLength.company || `Maksimipituus on ${maxLength.company} merkkiä`
      ]
    }
    return {
      rules
    }
  },
  computed: {
    ...mapState({
      form: state => state.form
    }),
    ...mapGetters({
      noutoOsoite: 'getNoutoOsoitePrint',
      toimitusOsoite: 'getToimitusOsoitePrint'
    })
  },
  methods: {
    ...mapActions(['updateForm']),
    input (fieldname, value) {
      this.updateForm({fieldname, value})
    },
    validate () {
      this.$refs.form.validate()
    },
  }
}
</script>
