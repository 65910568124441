<template>
  <button-custom
    :disabled="disabled"
    @click="order"
    color="primary"
    label="Yhteenveto"
    :loading="isPending"
    :floatRight="floatRight"
  >
  </button-custom>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import ButtonCustom from './ButtonCustom'
export default {
  name: 'button-place-order',
  components: {
    ButtonCustom
  },
  props: {
    valid: {
      type: Boolean,
      default: true,
    },
    floatRight: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      disabled: false,
    }
  },
  computed: {
    ...mapState({
      isPending: state => state.order.isPending
    }),
  },
  methods: {
    ...mapActions(['placeOrder', 'goToStep']),
    order () {
      if (this.disabled) {
        return
      }
      this.$eventBus.$emit('validateAll')
      this.$nextTick(() => {
        if (this.valid) {
          this.placeOrder()
            .then(() => {
              this.analyticsEvent()
              this.goToStep('checkout')
            })
        }
      })
    },
    analyticsEvent () {
      // Huudetaan analyticsille
      if (typeof mek_track === 'function') {
        // eslint-disable-next-line no-undef
        mek_track('rahtilaskuri', "'Valitse maksutapa'-nappi")
      }
    }
  }
}
</script>
