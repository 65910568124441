<template>
  <v-row :id="`tuote-row-${rowNumber}`" class="tuote-row">

    <v-col
      cols="12"
      sm="4"
      md="2"
    >
      <tuote-select
        class="tuote"
        :rowNumber="row.rowNumber"
        @selected="tuoteSelected"
      />
    </v-col>

    <v-col
      cols="12"
      sm="4"
      md="2"
    >
      <input-number
        class="pituus"
        v-model="row.pituus"
        label="Pituus (cm)"
        required
        @input="(val) => input('pituus', val)"
        mm-as-cm
        :readonly="!hasTuote || readonlyFields.pituus"
        :min="tuoteConstraints.pituus.min"
        :max="tuoteConstraints.pituus.max"
      ></input-number>
    </v-col>

    <v-col
      cols="12"
      sm="4"
      md="2"
    >
      <input-number
        class="leveys"
        v-model="row.leveys"
        label="Leveys (cm)"
        required
        @input="(val) => input('leveys', val)"
        mm-as-cm
        :readonly="!hasTuote || readonlyFields.leveys"
        :min="tuoteConstraints.leveys.min"
        :max="tuoteConstraints.leveys.max"
      ></input-number>
    </v-col>

    <v-col
      cols="12"
      sm="4"
      md="2"
    >
      <input-number
        class="korkeus"
        v-model="row.korkeus"
        label="Korkeus (cm)"
        required
        @input="(val) => input('korkeus', val)"
        mm-as-cm
        :readonly="!hasTuote || readonlyFields.korkeus"
        :min="tuoteConstraints.korkeus.min"
        :max="tuoteConstraints.korkeus.max"
      ></input-number>
    </v-col>

    <v-col
      cols="12"
      sm="4"
      md="2"
    >
      <input-number
        class="paino"
        v-model="row.paino"
        label="Yksikköpaino (kg)"
        required
        @input="(val) => input('paino', val)"
        :readonly="!hasTuote || readonlyFields.paino"
        :min="tuoteConstraints.paino.min"
        :max="tuoteConstraints.paino.max"
        :additionalRules="additionalPainoRules"
      ></input-number>
    </v-col>

    <v-col
      cols="12"
      sm="4"
      md="2"
    >
      <input-number
        class="maara"
        v-model="row.maara"
        label="Määrä"
        required
        @input="(val) => input('maara', val)"
        :readonly="!hasTuote || readonlyFields.maara"
        :min="tuoteConstraints.maara.min"
        :max="tuoteConstraints.maara.max"
        :additionalRules="additionalMaaraRules"
      ></input-number>
    </v-col>

    <v-col
      cols="12"
      md="9"
    >
      <v-text-field
        class="kuvaus"
        v-model="row.kuvaus"
        label="Kuvaus"
        required
        @input="(val) => input('kuvaus', val)"
        :readonly="!hasTuote || readonlyFields.kuvaus"
        :disabled="!hasTuote || readonlyFields.kuvaus"
        counter="255"
        :rules="kuvausRules"
        validate-on-blur
      ></v-text-field>
    </v-col>

    <v-col
      class="kokonaispaino margin-top"
      cols="12"
      md="2"
    >
      Kokonaispaino:<br/>{{kokonaispaino}}&nbsp;kg
    </v-col>

    <v-col
      cols="12"
      md="1"
    >
      <button-remove-tuote-row
        :rowNumber="row.rowNumber"
      />
    </v-col>

  </v-row>
</template>
<script>
import TuoteSelect from './TuoteSelect'
import InputNumber from './InputNumber'
import { mapGetters, mapActions } from 'vuex'
import { get } from 'lodash'
import ButtonRemoveTuoteRow from './ButtonRemoveTuoteRow';

// const TUOTE_FIN_LAVA = 27;
// const TUOTE_EUR_LAVA = 28;
const TUOTE_TEHO_LAVA = 29;
const TUOTE_RULLAKKO = 30;
const TUOTE_PAKETTI = 38;

export default {
  name: 'tuote-row',
  components: {
    ButtonRemoveTuoteRow,
    TuoteSelect,
    InputNumber,
  },
  props: {
    rowNumber: {
      type: Number,
    },
    row: {
      type: Object,
    },
    totalMaara: {
      type: Number,
      default: 0
    },
    totalPaino: {
      type: Number,
      default: 0
    }
  },
  data () {
    const maxTotalMaara = 250;
    const additionalMaaraRules = [
      () =>
        this.totalMaara <= maxTotalMaara ||
        `Voit tilata kerrallaan vain ${maxTotalMaara} tuotetta. Ostoskorissasi on ${this.totalMaara} tuotetta.`
    ];
    const maxTotalPaino = 7500;
    const additionalPainoRules = [
      () =>
        this.totalPaino <= maxTotalPaino ||
        `Tilauksen maksimipaino on ${maxTotalPaino} kg. Tilauksesi kokonaispaino on ${this.totalPaino} kg.`
    ];
    return {
      additionalMaaraRules,
      additionalPainoRules,
      kuvausRules: [
        v => !!v || 'Pakollinen tieto',
        v => (v && v.length <= 255) || 'Maksimipituus on 255 merkkiä',
      ]
    }
  },
  computed: {
    ...mapGetters(['tuoteRow', 'getTuote']),
    hasTuote() {
      return get(this.row, ['id'], null) !== null
    },
    tuote() {
      if (!this.hasTuote) {
        return null
      }
      return this.getTuote(get(this.row, ['id']))
    },
    tuoteConstraints() {
      const defaults = {
        pituus: {min: 0.1, max: 120},
        leveys: {min: 0.1, max: 120},
        korkeus: {min: 0.1, max: 235},
        paino: {min: 0.01, max: 1000},
        maara: {min: 1, max: 250},
      };

      const tuoteDrupalId = parseInt(get(this.tuote, ['drupalId'], -1), 10);

      // if (
      //   tuoteDrupalId === TUOTE_EUR_LAVA ||
      //   tuoteDrupalId === TUOTE_FIN_LAVA
      // ) {
      //   return defaults
      // }
      if (tuoteDrupalId === TUOTE_TEHO_LAVA) {
        return {
          ...defaults,
          paino: {min: 0.01, max: 400},
          korkeus: {min: 0.01, max: 150}
        }
      }
      if (tuoteDrupalId === TUOTE_RULLAKKO) {
        return {
          ...defaults,
          paino: {min: 0.01, max: 200},
          korkeus: {min: 0.01, max: 180}
        }
      }
      if (tuoteDrupalId === TUOTE_PAKETTI) {
        return {
          ...defaults,
          pituus: {min: 0.1, max: 60},
          leveys: {min: 0.1, max: 60},
          korkeus: {min: 0.1, max: 60},
          paino: {min: 0.01, max: 30},
        }
      }
      return defaults
    },
    kokonaispaino() {
      const num = get(this.row, ['maara'], 1) * get(this.row, ['paino'], 0);
      return Number.isNaN(num) ? 0 : num;
    },
    readonlyFields () {
      // Luetaan tuotteesta readonly
      const lockFields = [
        'pituus',
        'leveys',
        'korkeus',
        'paino',
        'kuvaus',
      ];
      const readonly = {};
      lockFields.forEach((fieldname) => {
        readonly[fieldname] = get(this.tuote, [`lukitse_${fieldname}`], 0) == 1
      });
      return readonly
    }
  },
  methods: {
    ...mapActions(['updateForm', 'updateTuoteRow']),
    input (fieldname, value) {
      this.updateTuoteRow({
        rowNumber: this.rowNumber,
        fieldname,
        value,
      })
    },
    tuoteSelected (tuote) {
      this.input('pituus', parseInt(tuote.pituus, 10));
      this.input('leveys', parseInt(tuote.leveys, 10));
      this.input('korkeus', parseInt(tuote.korkeus, 10));
      this.input('paino', parseInt(tuote.paino, 10));
      this.input('kuvaus', get(tuote, ['kuvaus'], ''));
    }
  }
}
</script>
