<template>
  <div>
    <spinner v-if="!appLoaded"></spinner>
    <template v-else>
      <!--<div>
        <div>valid: {{ valid }}</div>
        <div>addressValid: {{ addressValid }}</div>
        <div>tuoteValid: {{ tuoteValid }}</div>
        <div>customerValid: {{ customerValid }}</div>
        <v-btn @click="validateAll">validate-All</v-btn>
        <v-btn @click="validateAddress">validate-Address</v-btn>
        <v-btn @click="validateTuoteRows">validate-Tuote-Rows</v-btn>
        <v-btn @click="validateCustomer">validate-customer</v-btn>
        <v-btn @click="validateOrder">validate-order</v-btn>
        <hr/>
      </div>-->
      <!-- Address -->
      <v-container id="step-address" v-show="step === 'address'">
        <form-address ref="addressForm" v-model="addressValid"></form-address>

        <v-row>
          <v-col cols="12">
            <button-change-step
              validations="validateAddress"
              :valid="addressValid"
              step="next"
              label="Seuraava"
              :floatRight="true"
            />
          </v-col>
        </v-row>
      </v-container>

      <!-- Tuote -->
      <v-container id="step-tuote" v-show="step === 'tuote'">
        <form-tuote ref="tuoteForm" v-model="tuoteValid"></form-tuote>

        <v-row>
          <v-col cols="12">
            <button-calculate-price
              :valid="addressValid && tuoteValid"
              :floatRight="true"
            />
            <button-change-step
              step="prev"
              label="Edellinen"
              :floatRight="true"
            />
          </v-col>
        </v-row>
      </v-container>

      <!-- Customer -->
      <v-container id="step-customer" v-show="step === 'customer'">
        <v-row class="header">
          <v-col cols="12">
            <h2>Kuljetuksen hinta</h2>
          </v-col>
        </v-row>
        <order-summary
          :showOrderNumber="false"
          :showCustomer="false"
          :showDate="false"
          :showInfo="false"
          :showAddressContact="false"
          :showAddress="false"
          :showLaskutustiedot="false"
        ></order-summary>

        <v-row>
          <v-col cols="12">
            <button-change-step small step="address" label="Laske uudelleen"/>
          </v-col>
        </v-row>

        <v-row class="header margin-top">
          <v-col cols="12">
            <h2>Tilaa kuljetus</h2>
          </v-col>
        </v-row>

        <v-row class="header">
          <v-col cols="12">
            <h3>Lähetystiedot</h3>
          </v-col>
        </v-row>
        <form-order ref="orderForm" v-model="orderValid"></form-order>

        <v-row class="header">
          <v-col cols="12">
            <h3>Tilaajan tiedot</h3>
          </v-col>
        </v-row>
        <form-customer ref="customerForm" v-model="customerValid"></form-customer>

        <v-row>
          <v-col cols="12">
            <button-place-order
              :valid="valid"
              :floatRight="true"
            />
          </v-col>
        </v-row>
      </v-container>

      <!-- Checkout -->
      <v-container id="step-checkout" v-show="step === 'checkout'">
        <v-row class="header">
          <h2>Tilauksen tiedot</h2>
        </v-row>
        <order-summary :showOrderNumber="false" :showLaskutustiedot="false"></order-summary>

        <v-row>
          <v-col cols="12">
            <button-cancel-order label="Muuta tietoja"></button-cancel-order>
          </v-col>
        </v-row>

<!--  TODO: Checkout ei ole enää päällä -->
<!--  <template v-if="isCompany">-->
<!--    <v-row class="header">-->
<!--      <h2>Valitse maksutapa</h2>-->
<!--    </v-row>-->
<!--    <v-row>-->
<!--      <v-col cols="12">-->
<!--        <button-change-billing-type billingType="checkout"></button-change-billing-type>-->
<!--        <button-change-billing-type billingType="invoice"></button-change-billing-type>-->
<!--      </v-col>-->
<!--    </v-row>-->
<!--  </template>-->
<!--  <template v-if="!isCompany || billingType === 'checkout'">-->
<!--    <v-row class="header margin-top">-->
<!--      <h2>Siirry maksamaan</h2>-->
<!--    </v-row>-->
<!--    <v-row>-->
<!--      <checkout-providers />-->
<!--    </v-row>-->
<!--  </template>-->

        <template v-if="billingType === 'invoice'">
          <v-row class="header margin-top">
            <h2>Laskutusosoite</h2>
          </v-row>

          <form-billing-address ref="billingAddressForm" v-model="billingAddressValid" />

          <v-row>
            <v-col cols="12">
              <button-confirm-invoice-order :valid="billingAddressValid"/>
            </v-col>
          </v-row>
        </template>
      </v-container>

      <v-container>
        <v-row>
          <v-col cols="12" style="text-align: center">
            <dialog-delivery-terms
              v-model="showDialogDeliveryTerms"
              closeButtonLabel="Sulje"
            />

            <a class="mr-3" @click.stop.prevent="showDialogDeliveryTerms = !showDialogDeliveryTerms">
              Palvelun toimitusehdot
            </a>
            <!-- <a href="https://checkout.fi" class="ml-3 mr-3" target="_blank">-->
            <!--   <img src="https://jr-kuljetus.fi/files/checkout-logo-vaaka-RGB.png" width="150px"/>-->
            <!-- </a>-->
            <span class="ml-3">
              <img src="/hintalaskuri_dist/powered_by_google_on_white_hdpi.png" width="150px"/>
            </span>

          </v-col>
        </v-row>
      </v-container>
    </template>


  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Spinner from '@/components/Spinner';
import ButtonCalculatePrice from '@/components/ButtonCalculatePrice'
import ButtonChangeStep from '@/components/ButtonChangeStep'
import ButtonPlaceOrder from '@/components/ButtonPlaceOrder'
import ButtonCancelOrder from '@/components/ButtonCancelOrder'
// import ButtonChangeBillingType from '@/components/ButtonChangeBillingType'
import ButtonConfirmInvoiceOrder from '@/components/ButtonConfirmInvoiceOrder'
import FormAddress from '@/components/FormAddress'
import FormTuote from '@/components/FormTuote'
import FormCustomer from '@/components/FormCustomer'
import FormOrder from '@/components/FormOrder'
import FormBillingAddress from '@/components/FormBillingAddress'
// import CheckoutProviders from '@/components/checkout/CheckoutProviders'
import OrderSummary from '@/components/OrderSummary';
// import DialogPaymentMethods from "@/components/DialogPaymentMethods";
import DialogDeliveryTerms from "@/components/DialogDeliveryTerms";

export default {
  name: 'page-calculator',
  components: {
    OrderSummary,
    Spinner,
    ButtonCalculatePrice,
    ButtonChangeStep,
    ButtonPlaceOrder,
    ButtonCancelOrder,
    // ButtonChangeBillingType,
    ButtonConfirmInvoiceOrder,
    // Price,
    FormAddress,
    FormTuote,
    FormCustomer,
    FormOrder,
    FormBillingAddress,
    // CheckoutProviders,
    // DialogPaymentMethods
    DialogDeliveryTerms
  },
  data: () => ({
    addressValid: false,
    tuoteValid: false,
    customerValid: false,
    orderValid: false,
    billingAddressValid: false,
    // showDialogPaymentMethods: false,
    showDialogDeliveryTerms: false,
  }),
  mounted () {
    if (!this.appLoaded) {
      this.loadApp()
    }

    this.$eventBus.$on('validateAll', () => this.validateAll())
    this.$eventBus.$on('validateAddress', () => this.validateAddress())
    this.$eventBus.$on('validateTuoteRows', () => this.validateTuoteRows())
    this.$eventBus.$on('validateCustomer', () => this.validateCustomer())
    this.$eventBus.$on('validateOrder', () => this.validateOrder())
    this.$eventBus.$on('validateBillingAddress', () => this.validateBillingAddress())
  },
  destroyed () {
    this.$eventBus.$off('validateAll')
    this.$eventBus.$off('validateAddress')
    this.$eventBus.$off('validateTuoteRows')
    this.$eventBus.$off('validateCustomer')
    this.$eventBus.$off('validateOrder')
    this.$eventBus.$off('validateBillingAddress')
  },
  computed: {
    ...mapGetters(['appLoaded', 'step', 'billingType', 'isCompany']),
    valid () {
      return this.addressValid &&
        this.tuoteValid &&
        this.customerValid &&
        this.orderValid
        // && this.billingAddressValid
    }
  },
  methods: {
    ...mapActions(['loadApp']),
    validateAll () {
      this.validateAddress()
      this.validateTuoteRows()
      this.validateCustomer()
      this.validateOrder()
      // this.validateBillingAddress()
    },
    validateAddress () {
      this.$refs.addressForm.validate()
    },
    validateTuoteRows () {
      this.$refs.tuoteForm.validate()
    },
    validateCustomer () {
      this.$refs.customerForm.validate()
    },
    validateOrder () {
      this.$refs.orderForm.validate()
    },
    validateBillingAddress () {
      this.$refs.billingAddressForm.validate()
    }
  }
};
</script>
