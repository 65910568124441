<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" width="810px">
      <v-card>

        <v-card-title>
          <span>Tilaajan vastuut</span>
        </v-card-title>
        <v-card-text>
          <p>
            Tilaaja vastaa tilaustietojen oikeellisuudesta ja siitä,
            että kuljetukseen ei ole pakattu vaarallisia,
            hajuhaittaa aiheuttavia aineita tai mitään muutakaan terveydelle vaarallista,
            luontoa pilaavaa tai laitonta materiaalia tai ainetta.
          </p>
          <p>
            Emme suorita muuttopalveluja. Kuljetettava tavara tulee pakata huolellisesti.
            Palveluntarjoajalla on oikeus olla ottamatta tuotetta kyytiin jos tuote on pakattu huonosti.
            Mikäli noudetun tavaran koko, paino, korkeus tai muut määreet eivät vastaa tilauksessa ilmoitettuihin,
            on palveluntarjoajalla oikeus laskuttaa tilaus toteutuneen mukaan, kulloinkin voimassa olevan hinnaston mukaisesti.
          </p>
          <p>
            Jos kuljetettava tuote on lava, tuote pitää olla valmiiksi pakattu lavalle.
            Lavan/rullakon nouto tai vienti tulee olla esteetön.
            Lava pitää pystyä noutamaan tai toimittamaan pumppukärryillä maahan/lastauslaituriin.
          </p>
          <p>
            Tavara tulee olla valmis noudettavaksi heti kun tilaus on tehty.
            Lähetyksessä jokaisessa irto kollissa pitää olla rahtikirja/kirjat paikoilleen
            laitettuna kun nouto suoritetaan. Valmiiksi täytetyn rahtikirjan/kirjat saat sähköpostiisi kun tilaus on tehty.
            Lähetys noudetaan samana tai seuraavana arkipäivänä.
          </p>
        </v-card-text>

        <v-card-title>
          <span>Tilauksen peruuttaminen</span>
        </v-card-title>
        <v-card-text>
          <p>
            Palvelu tuotetaan etämyyntinä ja rinnastettavissa tiettyyn ajankohtaan sovittuun tavaroiden kuljettamiseen.
            Tällaisilla palveluilla ei ole peruutus- tai palautusoikeutta.
            Mikäli tilatun palvelun toimittaminen ei ole vielä lähtenyt käyntiin tai muulla tavoin edennyt toimitusprosessissamme,
            voit puhelimitse tiedustella mahdollisuutta sen peruuttamiseen.
          </p>
        </v-card-text>

        <v-card-title>
          <span>Tilauksen toimittaminen</span>
        </v-card-title>
        <v-card-text>
          <p>
            Kuljetustilaus toimitetaan pääasiallisesti kuljetustilauksessa valittuna päivänä.
          </p>
          <p>
            Kuljetuspalvelut Jari Rajala Oy varaa oikeuden perua tilaus tai muuttaa sen toimituspäivää.
            Tällaisista muutoksista ilmoitetaan tilaajalle mahdollisimman pian.
            Kuljetuspalvelut Jari Rajala Oy ei vastaa mistään tilaajalle mahdollisten viivästymisten tai
            peruuntumisen aiheuttamista suorista tai epäsuorista kustannuksista.
          </p>
        </v-card-text>

<!--<v-card-title>-->
<!--  <span>Maksuehdot</span>-->
<!--</v-card-title>-->
<!--<v-card-text>-->
<!--  <p>-->
<!--    Palvelu maksetaan kuljetustilauksen yhteydessä Checkout-maksunvälityspalvelulla, jonka tuottaa Checkout Finland Oy.-->
<!--  </p>-->
<!--  <p>-->
<!--    Lue tarkemmin-->
<!--    <a href="https://www.checkout.fi/ehdot-ja-sopimukset/maksuehdot" target="_blank">-->
<!--      tästä <v-icon style="text-decoration: none;" color="primary">mdi-open-in-new</v-icon>-->
<!--    </a>.-->
<!--  </p>-->
<!--</v-card-text>-->

        <v-card-text>
          <strong>
            Palvelun tarjoaa Kuljetuspalvelut Jari Rajala Oy, 1975949-8<br/>
            Timpurinkuja 9, 06150 PORVOO<br/>
            puh. 040 596 3257<br/>
            info@jr-kuljetus.fi
          </strong>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <button-custom :label="closeButtonLabel" @click="accept" classes="ma-3"/>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import ButtonCustom from "@/components/ButtonCustom";
export default {
  name: 'dialog-delivery-terms',
  components: {
    ButtonCustom
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    closeButtonLabel: {
      type: String,
      default: 'Hyväksyn'
    }
  },
  // Häxätään dialogin click-outside toimimaan isommalla z-indexillä sulautettuna rajalan saittiin.
  // TODO: Keksitään miten z-index nostetaan siten, ettei click-outside häiriinny
  watch: {
    dialog (val) {
      if (val) {
        document.addEventListener('mousedown', this.mousedownHandler)
      }
      else {
        document.removeEventListener('mousedown', this.mousedownHandler)
      }
    }
  },
  destroyed () {
    document.removeEventListener('mousedown', this.mousedownHandler)
  },
  computed: {
    dialog: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    accept () {
      this.$emit('accept')
      this.dialog = false
    },
    mousedownHandler (e) {
      if (e.target.classList.contains('v-overlay__scrim')) {
        this.dialog = false
      }
    }
  }
}
</script>
