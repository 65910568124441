<template>
  <v-container>
    <spinner v-if="!appLoaded"></spinner>
    <template v-else>

      <v-row>
        <v-col cols="6">
          <v-text-field
            v-model="data.from"
            label="Nouto postinumero"
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model="data.to"
            label="Toimitus postinumero"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row v-for="(product, i) in data.products" :key="i">
        <v-col>
          <v-select
            :items="tuoteOptions"
            label="Tuote"
            v-model="product.product"
            item-value="tunniste"
            item-text="name"
            @change="(val) => selected(val, i)"
          ></v-select>

          <input-number
            v-model="product.length_mm"
            label="Pituus (cm)"
            mm-as-cm
          ></input-number>
          <input-number
            v-model="product.width_mm"
            label="Leveys (cm)"
            mm-as-cm
          ></input-number>
          <input-number
            v-model="product.height_mm"
            label="Korkeus (cm)"
            mm-as-cm
          ></input-number>
          <input-number
            v-model="product.weight_kg"
            label="Paino (kg)"
          ></input-number>
          <input-number
            v-model="product.count"
            label="Määrä"
          ></input-number>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-btn @click="addProduct">Lisää tuote</v-btn>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-btn @click="calculate">Laske hinta</v-btn>
        </v-col>
      </v-row>

      <v-row>
        <v-col v-if="error !== null" cols="12">Error: {{error}}</v-col>
        <template v-else>
          <v-col cols="4"><strong>Hinta alv. 0%: </strong>{{price.price / 100}} €</v-col>
          <v-col cols="4"><strong>Alv:</strong> {{price.price_tax / 100}} €</v-col>
          <v-col cols="4"><strong>Yhteensä: </strong>{{price.price_total / 100}} €</v-col>
        </template>
      </v-row>
    </template>
  </v-container>
</template>
<script>
import {mapActions, mapGetters, mapState} from "vuex";
import Spinner from '@/components/Spinner';
import InputNumber from "@/components/InputNumber";
import api from '@/api'
export default {
  name: 'page-test-simple-calculation',
  components: {
    InputNumber,
    Spinner
  },
  data () {
    return {
      isPending: false,
      data: {
        from: '',
        to: '',
        products: []
      },
      price: {
        price: null,
        price_tax: null,
        price_total: null
      },
      error: null
    }
  },
  computed: {
    ...mapGetters(['appLoaded']),
    ...mapState({
      tuoteEntities: state => state.tuoteEntities
    }),
    tuoteOptions () {
      return this.tuoteEntities
        .map((t) => ({tunniste: t.tunniste, name: t.name}))
    }
  },
  created () {
    this.loadApp()
    this.addProduct()
  },
  methods: {
    ...mapActions(['loadApp']),
    addProduct () {
      const product = {
        product: 'tuote_27',
        length_mm: 1000,
        width_mm: 1200,
        height_mm: 0,
        weight_kg: 0,
        count: 1
      };
      this.data.products = [...this.data.products, product]
    },
    calculate () {
      this.isPending = true
      api.testSimpleCalculation(this.data)
        .then((res) => {
          this.price = res.data.price
          this.error = res.data.error
        })
        .catch((e) => {
          console.log(e)
        })
    },
    selected (value, i) {
      const tuoteEntity = this.tuoteEntities.find((t) => t.tunniste === value)
      this.data.products[i].length_mm = tuoteEntity.pituus
      this.data.products[i].width_mm = tuoteEntity.leveys
      this.data.products[i].height_mm = tuoteEntity.korkeus
      this.data.products[i].weight_kg = tuoteEntity.paino
    }
  }
}
</script>