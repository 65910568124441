import Vuex from 'vuex'
import Vue from 'vue'
import createPersistedState from 'vuex-persistedstate'
import * as getters from './getters'
import * as mutations from './mutations'
import * as actions from './actions'
import initialState from './initialState'
import cloneDeep from 'lodash/cloneDeep'
import get from 'lodash/get'

Vue.use(Vuex)

export default function () {
  const config = {
    state: cloneDeep(initialState),
    getters,
    mutations,
    actions,
    plugins: [
      createPersistedState({
        key: 'hintalaskuri',
        paths: [
          // Pidetään nämä tiedot tallessa sivulatausten välissä
          'session',
          'timestamp',
          'form',
          'autocompleteResults',
        ],
        getState: function(key, storage) {
          let value;
          try {
            const parsed = (value = storage.getItem(key)) && typeof value !== "undefined"
              ? JSON.parse(value)
              : undefined;
            // Katotaan onko selaimen cache vanhentunut
            const validTimeInMin = 12 * 60
            const validTimeInMs = validTimeInMin * 60 * 1000
            // Staten muokkausaika
            const timestamp = get(parsed, 'timestamp', 0)
            // Palautetaan undefined, jos vanhentunut
            const isExpired = (new Date().getTime() - timestamp) > validTimeInMs
            return isExpired
              ? undefined
              : parsed
            // eslint-disable-next-line no-empty
          } catch (err) {}
          return undefined;
        }
      })
    ],
  }
  const store = new Vuex.Store(config);
  if (window.Cypress) {
    window.__store__ = store
  }
  return store
}
