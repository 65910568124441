<template>
  <div class="order-summary-tuotteet order-summary-tuotteet-blocks">
    <div
      v-for="(tuote, i) in tuotteet"
      :key="tuote.id"
      :class="`tuote-block tuote-block-${i}`"
    >
      <div class="font-weight-bold">{{tuote.maara}}x {{tuote.name}}</div>
      <div>{{tuote.pituus}} x {{tuote.leveys}} x {{tuote.korkeus}} cm. {{tuote.paino}} kg.</div>
      <div class="kuvaus">
        Kokonaispaino: {{tuote.kokonaispaino}} kg.<br/>
        {{tuote.kuvaus}}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'order-summary-tuotteet-blocks',
  props: {
    tuotteet: {
      type: Array,
      default: () => [],
    },
  },
}
</script>
