<template>
  <v-container class="order-summary">

    <v-row v-if="showOrderNumber">
      <v-col cols="12" md="6" v-if="showOrderNumber" class="order-number">
        <h3 class="header">Tilausnumero</h3>
        {{ tilausnro }}
      </v-col>

    </v-row>

    <v-row v-if="showTuote || showPrice">
      <v-col cols="12" md="3" v-if="showPrice" class="price">
        <h3 class="header margin-bottom">Hinta</h3>
        <!-- <div class="tax-0">Hinta: {{hintaAlv0}}&nbsp;€</div>-->
        <!-- <div class="tax">Alv 24%: {{hintaAlv}}&nbsp;€</div>-->
        <div class="total">{{ hintaAlv0 }}&nbsp;€ <span class="tax">(Alv. 0%)</span></div>
      </v-col>

      <v-col cols="12" md="9" v-if="showPrice">
        <h3 class="header margin-bottom">Tuotteet</h3>
        <order-summary-tuotteet-blocks :tuotteet="tuotteet" />
      </v-col>

    </v-row>

    <v-row v-if="showAddress" class="address">
      <v-col cols="12" sm="3" class="from">
        <h3 class="header">Nouto</h3>
        <div v-if="showNoutoYritys">{{noutoYritys}}</div>
        <div>{{noutoOsoite}}</div>
        <div v-if="showAddressContact">{{ noutoYhteyshenkilo }}</div>
      </v-col>
      <v-col cols="12" sm="3" class="to">
        <h3 class="header">Toimitus</h3>
        <div v-if="showToimitusYritys">{{toimitusYritys}}</div>
        <div>{{toimitusOsoite}}</div>
        <div v-if="showAddressContact">{{ toimitusYhteyshenkilo }}</div>
      </v-col>
    </v-row>

    <v-row v-if="showLaskutustiedot">
      <v-col cols="12" sm="3" class="billingInfo">
        <h3 class="header">Laskutustiedot</h3>
        <div v-for="(tieto, i) in laskutusTiedot" :key="i">{{tieto}}</div>
      </v-col>
    </v-row>

<!--    <v-row v-if="showTuote" class="tuote">-->
<!--      <v-col cols="12">-->
<!--        <h3 class="header">Tuotteet</h3>-->
<!--        <order-summary-tuotteet-table :tuotteet="tuotteet" />-->
<!--      </v-col>-->
<!--    </v-row>-->

    <v-row v-if="showCustomer" class="customer">
      <v-col cols="12" sm="4" md="3">
        <h3 class="header">Etunimi</h3>
        <div class="firstname">{{asiakastiedot.yhteyshenkilo.etunimi}}</div>
      </v-col>
      <v-col cols="12" sm="4" md="3">
        <h3 class="header">Sukunimi</h3>
        <div class="lastname">{{asiakastiedot.yhteyshenkilo.sukunimi}}</div>
      </v-col>
      <v-col cols="12" sm="4" md="3">
        <h3 class="header">Sähköposti</h3>
        <div class="email">{{asiakastiedot.yhteyshenkilo.email}}</div>
      </v-col>
      <v-col cols="12" sm="4" md="3">
        <h3 class="header">Puhelin</h3>
        <div class="phone">{{asiakastiedot.yhteyshenkilo.puhelin}}</div>
      </v-col>
      <v-col cols="12" sm="4" md="3">
        <h3 class="header">Osoite</h3>
        <div class="address">{{asiakastiedot.kayntiosoite}}</div>
      </v-col>
      <v-col cols="12" sm="4" md="3">
        <h3 class="header">Yritys</h3>
        <div class="company">{{asiakastiedot.company}}</div>
      </v-col>
      <v-col cols="12" sm="4" md="3">
        <h3 class="header">Y-tunnus</h3>
        <div class="business-id">{{asiakastiedot.y_tunnus}}</div>
      </v-col>
    </v-row>

    <v-row v-if="showDate || showInfo">
      <v-col cols="12" sm="4" md="3" v-if="showDate">
        <h3 class="header">Toivottu toimituspäivä</h3>
        <div class="date">{{toivottuToimitus}}</div>
      </v-col>

      <v-col cols="12" sm="8" md="9" v-if="showInfo">
        <h3 class="header">Toimituksen lisätiedot</h3>
        <div class="order-info">{{tilauksenLisatiedot}}</div>
      </v-col>
    </v-row>

  </v-container>
</template>
<script>
// import OrderSummaryTuotteetTable from '@/components/OrderSummaryTuotteetTable'
import OrderSummaryTuotteetBlocks from '@/components/OrderSummaryTuotteetBlocks'
import { mapState, mapGetters } from 'vuex'
import { get, isEmpty } from 'lodash'
import moment from 'moment'
export default {
  name: 'order-summary',
  components: {
    // OrderSummaryTuotteetTable,
    OrderSummaryTuotteetBlocks
  },
  props: {
    showAddress: {
      type: Boolean,
      default: true,
    },
    showTuote: {
      type: Boolean,
      default: true,
    },
    showLaskutustiedot: {
      type: Boolean,
      default: true,
    },
    showCustomer: {
      type: Boolean,
      default: true,
    },
    showDate: {
      type: Boolean,
      default: true,
    },
    showInfo: {
      type: Boolean,
      default: true,
    },
    showOrderNumber: {
      type: Boolean,
      default: true,
    },
    showPrice: {
      type: Boolean,
      default: true,
    },
    showAddressContact: {
      type: Boolean,
      default: true,
    }
  },
  computed: {
    ...mapState({
      tilaus: state => state.order.data,
      form: state => state.form,
      price: (state) => state.price,
    }),
    ...mapGetters({
      tuoteRows: 'tuoteRows',
      kayntiosoite: 'getKayntiosoitePrint',
      noutoOsoite: 'getNoutoOsoitePrint',
      toimitusOsoite: 'getToimitusOsoitePrint',
      laskutusTiedot: 'getLaskutustiedotPrint',
      tuotteet: 'getTuotePrints',
      yhteyshenkilo: 'getYhteyshenkiloPrint',
    }),
    noTilaus () {
      return this.tilaus === null || this.tilaus === undefined
    },
    lahetykset () {
      return get(this.tilaus, 'lahetykset', [])
    },
    asiakas () {
      return get(this.tilaus, ['asiakas', 0])
    },
    asiakastiedot () {
      let y_tunnus = get(this.asiakas, 'y_tunnus')
      let company = '-'
      if (!isEmpty(y_tunnus) && y_tunnus !== '-') {
        company = get(this.asiakas, 'name')
      }
      if (isEmpty(y_tunnus)) {
        y_tunnus = '-'
      }
      return {
        yhteyshenkilo: this.yhteyshenkilo,
        kayntiosoite: this.kayntiosoite,
        company,
        y_tunnus
      }
    },
    noutoYritys () {
      let lahetys = get(this.lahetykset, '0')
      let name = [
        get(lahetys, ['nouto_nimi'], ''),
      ].join(' ')
      // Täytetään tarvittavat kentät form-datalla, jos ei tilausta
      if (this.noTilaus) {
        name = this.form.from.contact.company
      }
      return [
        name,
      ].join(', ')
    },
    toimitusYritys () {
      let lahetys = get(this.lahetykset, '0')
      let name = [
        get(lahetys, ['toimitus_nimi'], ''),
      ].join(' ')
      // Täytetään tarvittavat kentät form-datalla, jos ei tilausta
      if (this.noTilaus) {
        name = this.form.to.contact.company
      }
      return [
        name,
      ].join(', ')
    },
    showNoutoYritys () {
      // Näytetään vain jos eroaa yhteyshenkilön nimestä
      let lahetys = get(this.lahetykset, '0')
      let name = [
        get(lahetys, ['nouto_yhteyshenkilo_etunimi'], ''),
        get(lahetys, ['nouto_yhteyshenkilo_sukunimi'], ''),
      ].join(' ')
      if (this.noTilaus) {
        name = [
          this.form.from.contact.firstname,
          this.form.from.contact.lastname,
        ].join(' ')
      }
      return this.noutoYritys !== name
    },
    showToimitusYritys () {
      // Näytetään vain jos eroaa yhteyshenkilön nimestä
      let lahetys = get(this.lahetykset, '0')
      let name = [
        get(lahetys, ['toimitus_yhteyshenkilo_etunimi'], ''),
        get(lahetys, ['toimitus_yhteyshenkilo_sukunimi'], ''),
      ].join(' ')
      // Täytetään tarvittavat kentät form-datalla, jos ei tilausta
      if (this.noTilaus) {
        name = [
          this.form.from.contact.firstname,
          this.form.from.contact.lastname,
        ].join(' ')
      }

      return this.toimitusYritys !== name
    },
    noutoYhteyshenkilo () {
      let lahetys = get(this.lahetykset, '0')
      let name = [
        get(lahetys, ['nouto_yhteyshenkilo_etunimi'], ''),
        get(lahetys, ['nouto_yhteyshenkilo_sukunimi'], ''),
      ].join(' ')
      let phone = get(lahetys, ['nouto_yhteyshenkilo_puhelin'], '-')
      // Täytetään tarvittavat kentät form-datalla, jos ei tilausta
      if (this.noTilaus) {
        name = [
          this.form.from.contact.firstname,
          this.form.from.contact.lastname,
        ].join(' ')
        phone = this.form.from.contact.phone
      }
      return [
        name,
        phone,
      ].join(', ')
    },
    toimitusYhteyshenkilo () {
      let lahetys = get(this.lahetykset, '0')
      let name = [
        get(lahetys, ['toimitus_yhteyshenkilo_etunimi'], ''),
        get(lahetys, ['toimitus_yhteyshenkilo_sukunimi'], ''),
      ].join(' ')
      let phone = get(lahetys, ['toimitus_yhteyshenkilo_puhelin'], '-')
      // Täytetään tarvittavat kentät form-datalla, jos ei tilausta
      if (this.noTilaus) {
        name = [
          this.form.from.contact.firstname,
          this.form.from.contact.lastname,
        ].join(' ')
        phone = this.form.from.contact.phone
      }
      return [
        name,
        phone,
      ].join(', ')
    },
    toivottuToimitus () {
      let lahetys = get(this.lahetykset, '0')
      let date = moment.unix(get(lahetys, ['toivottu_toimitus'], 0))
      if (this.noTilaus) {
        date = moment(this.form.date)
      }
      return date.format('DD.MM.YYYY')
    },
    tilauksenLisatiedot () {
      let lahetys = get(this.lahetykset, '0')
      let viesti = get(lahetys, 'viesti', '')
      if (this.noTilaus) {
        viesti = this.form.info
      }
      if (isEmpty(viesti)) {
        viesti = '-'
      }
      return viesti
    },
    tilausnro () {
      return get(this.tilaus, ['tilausnro'], '-')
    },
    // Alviton hinta
    hintaAlv0 () {
      let hinta = get(this.tilaus, ['hinta'], 0)
      if (this.noTilaus) {
        hinta = parseInt(get(this.price, ['price'], 0)) / 100
      }
      return this.printDecimal(hinta)
    },
    // Alvin osuus
    hintaAlv () {
      let hinta = get(this.tilaus, ['hinta_alv'], 0)
      if (this.noTilaus) {
        hinta = parseInt(get(this.price, ['price_tax'], 0)) / 100
      }
      return this.printDecimal(hinta)
    },
    // Totaalihinta
    hinta () {
      let hinta = get(this.tilaus, ['hinta_total'], 0)
      if (this.noTilaus) {
        hinta = parseInt(get(this.price, ['price_total'], 0)) / 100
      }
      return this.printDecimal(hinta)
    }
  },
  methods: {
    printDecimal (number) {
      return parseFloat(Math.round(number * 100) / 100)
        .toFixed(2)
        .replace('.', ',')
      ;
    }
  }
}
</script>
