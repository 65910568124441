import Vue from 'vue'
import Vuex from 'vuex'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import axios from 'axios';
import VueAxios from 'vue-axios';
import store from './store'
import Fragment from 'vue-fragment'
import VueRouter from 'vue-router'
import Calculator from '@/pages/Calculator'
import OrderConfirmed from '@/pages/OrderConfirmed'
import OrderCanceled from '@/pages/OrderCanceled'
import TestSimpleCalculation from "@/pages/TestSimpleCalculation";

Vue.use(Fragment.Plugin)

Vue.config.productionTip = false

Vue.use(Vuex)

axios.defaults.baseURL = process.env.VUE_APP_BACKEND_URL
Vue.use(VueAxios, axios)

Vue.use(VueRouter)

const router = new VueRouter({
  routes: [
    { name: 'calculator', path: '/', component: Calculator },
    { name: 'order-confirmed', path: '/payment/:orderId/:paymentId/success', component: OrderConfirmed },
    { name: 'invoice-order-confirmed', path: '/order/:orderId/confirm', component: OrderConfirmed },
    { name: 'order-canceled', path: '/payment/:orderId/:paymentId/cancel', component: OrderCanceled },
    { name: 'test-simple-calculation', path: '/test-simple-calculation', component: TestSimpleCalculation },
  ],
});

Vue.prototype.$eventBus = new Vue();

new Vue({
  vuetify,
  store,
  router,
  render: h => h(App)
}).$mount('#app')
