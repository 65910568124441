<template>
  <button-custom
    :disabled="disabled"
    @click="order"
    color="primary"
    :label="label"
    :loading="isPending"
    :floatRight="floatRight"
  >
  </button-custom>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import ButtonCustom from './ButtonCustom'
import { get } from 'lodash'
export default {
  name: 'button-place-order',
  components: {
    ButtonCustom
  },
  props: {
    valid: {
      type: Boolean,
      default: true,
    },
    label: {
      type: String,
      default: 'Peruuta',
    },
    floatRight: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      disabled: false,
    }
  },
  computed: {
    ...mapState({
      isPending: state => state.order.isPending,
      orderId: state => get(state, ['order', 'data', 'id'], null),
      paymentId: state => get(state, ['checkout', 'paymentId'], null),
    }),
  },
  methods: {
    ...mapActions(['cancelOrder', 'goToStep']),
    order () {
      if (this.disabled) {
        return
      }
      // this.$eventBus.$emit('validateAll')
      this.$nextTick(() => {
        if (this.valid) {
          this.cancelOrder({
            orderId: this.orderId,
            paymentId: this.paymentId,
            force: true,
          })
            .then(() => this.goToStep('address'))
        }
      })
    }

  }
}
</script>
