<template>
  <v-text-field
    v-model="localValue"
    type="number"
    :label="label"
    :required="required"
    :rules="rules"
    :hint="hint"
    :readonly="readonly"
    :disabled="readonly"
    persistent-hint
    validate-on-blur
    @focus="$event.target.select()"
  ></v-text-field>
</template>

<script>
export default {
  name: 'input-number',
  props: {
    value: {
      type: [Number, String],
      required: true,
    },
    mmAsCm: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    label: {},
    min: {
      type: Number,
      default: 0
    },
    max: {
      type: Number,
      default: 10000000
    },
    hint: {
      type: String,
      default: ''
    },
    readonly: {
      type: Boolean,
      default: false
    },
    additionalRules: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    localValue: {
      get () {
        if (this.mmAsCm) {
          return parseFloat(this.value) / 10
        }
        return parseFloat(this.value)
      },
      set (val) {
        if (this.mmAsCm) {
          this.$emit('input', parseFloat(val) * 10)
        } else {
          this.$emit('input', parseFloat(val))
        }
      }
    },
    rules () {
      let retval = []
      if (this.required) {
        retval.push(v => !!v || 'Pakollinen tieto')
      }
      retval.push(v => v >= this.min || `Minimiarvo on ${this.min}`)
      retval.push(v => v <= this.max || `Maksimiarvo on ${this.max}`)
      retval = [...retval, ...this.additionalRules]
      return retval
    }
  }
}
</script>
