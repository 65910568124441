<template>
  <button-custom
    :label="label"
    v-if="allowRemove"
    @click="remove"
    icon
    color="error"
    :floatRight="floatRight"
  >
    <v-icon>mdi-delete</v-icon>
  </button-custom>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import ButtonCustom from '@/components/ButtonCustom'
export default {
  name: 'button-remove-tuote-row',
  components: {
    ButtonCustom
  },
  props: {
    rowNumber: {
      type: Number
    },
    label: {
      type: String,
      default: 'Poista',
    },
    floatRight: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState({
      allowRemove: (state) => {
        return state.form.tuoteRows.length > 1
      }
    })
  },
  methods: {
    ...mapActions(['removeTuoteRow']),
    remove () {
      this.removeTuoteRow(this.rowNumber)
    }
  }
}
</script>
