import api from '../api'
import moment from 'moment'
import { newTuoteRow } from './initialState'
import { get } from 'lodash'
import uuid from 'uuid/v4'

/**
 * @param commit
 */
export const resetApp = ({commit}) => {
  commit('RESET_STATE')
}

/**
 * @param commit
 */
export const resetForm = ({commit}) => {
  commit('RESET_STATE', 'form')
  // Tyhjennetään myös session, jotta saadaan aina uusi avain tilausprosessille
  commit('RESET_STATE', 'session')
}

/**
 * @param commit
 * @param type
 */
export const resetFormAddress = ({commit}, type) => {
  commit('RESET_ADDRESS_STATE', type)
}

/**
 * @param commit
 * @param errors
 */
export const setErrors = ({commit}, errors) => {
  // Mäpätään arraksi, jos ei ole jo
  if (!Array.isArray(errors)){
    errors = [errors]
  }
  // Default käyttäjälle näkyvä errori
  const defaultErrorMessage = 'Odottamaton virhe'
  // Mapataan storeen
  const mapped = errors.map((err) => {
    // Tuetaan myös suoraan stringinä passaamista
    let message = err
    let status = 0
    // Käsitellään Errorit
    if (err instanceof Error) {
      message = defaultErrorMessage
      status = get(err, ['response', 'status'], 0)
      if (status === 400) {
        message = get(err, ['response', 'data', 'error'], defaultErrorMessage)
      }
    }
    return { message, status, id: uuid() }
  })
  commit('SET_ERRORS', mapped)
}

/**
 * @param commit
 */
export const clearErrors = ({commit}) => {
  commit('SET_ERRORS', [])
}

/**
 * @param commit
 * @param dispatch
 * @returns {Promise<any>}
 */
export const loadApp = ({ commit, dispatch }) => {
  dispatch('clearErrors')
  return new Promise((resolve, reject) => {
    Promise.all([
      api.loadApp(),
      dispatch('loadPyhapaivat')
    ])
      .then((responses) => {
        const [loadResponse, pyhapaivaResponse] = responses
        commit('INIT_APP', {
          loadResponse: loadResponse.data,
          pyhapaivaResponse: pyhapaivaResponse.data
        })
        resolve(responses)
      })
      .catch((err) => {
        dispatch('setErrors', err)
        reject(err)
      })
  })
}

/**
 * @param commit
 * @param dispatch
 * @param query
 * @returns {Promise<any>}
 */
export const loadPyhapaivat = ({commit, dispatch}, query) => {
  dispatch('clearErrors')
  commit('REQUEST_PYHAPAIVAT')

  if (query === undefined) {
    query = {}
  }
  // Defaulttina seuraavan vuoden pyhät
  if (query.min === undefined) {
    query.min = moment().startOf('month').format('YYYY-MM');
  }
  if (query.max === undefined) {
    query.max = moment().add(1, 'year').endOf('month').format('YYYY-MM');
  }
  // Vain nämä kentät
  query.fields = ['name', 'date']

  return new Promise((resolve, reject) => {
    api.loadPyhapaivat(query)
      .then((res) => {
        commit('RECEIVE_PYHAPAIVAT', res.data)
        resolve(res)
      })
      .catch((err) => {
        commit('RECEIVE_PYHAPAIVAT', [])
        dispatch('setErrors', err)
        reject(err)
      })
  })
}

/**
 * @param commit
 * @param key
 */
export const resetAutocompleteAddress = ({commit}, key) => {
  commit('RESET_AUTOCOMPLETE_RESULTS', {
    key,
    results: []
  })
}

/**
 * @param state
 * @param commit
 * @param key
 * @param thoroughfare
 * @param postalCode
 * @param city
 * @returns {Promise<unknown>}
 */
export const autocompleteAddress = ({state, commit}, {key, thoroughfare, postalCode, city}) => {
  return new Promise((resolve, reject) => {
    api.autocompleteAddress({
      operation: 'autocompleteStreet',
      sessiontoken: state.session,
      thoroughfare,
      postalCode,
      city,
      country: 'Suomi'
    })
      .then((res) => {
        const predictions = get(res, ['data', 'predictions'], [])
        commit('RECEIVE_AUTOCOMPLETE_RESULTS', {
          key,
          results: predictions
        })
        resolve(predictions)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

/**
 * @param state
 * @param commit
 * @param key
 * @param thoroughfare
 * @param streetNumber
 * @param city
 * @returns {Promise<unknown>}
 */
export const autocompletePostalcode = ({state, commit}, {key, thoroughfare, streetNumber, city}) => {
  return new Promise((resolve, reject) => {
    api.autocompleteAddress({
      operation: 'predictPostalCode',
      sessiontoken: state.session,
      thoroughfare,
      streetNumber,
      city,
      country: 'FI'
    })
      .then((res) => {
        const predictions = get(res, ['data', 'predictions'], [])
        commit('RECEIVE_AUTOCOMPLETE_RESULTS', {
          key,
          results: predictions
        })
        resolve(predictions)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

/**
 * @param state
 * @param commit
 * @param dispatch
 * @returns {Promise<any>}
 */
export const calculatePrice = ({ state, commit, dispatch }) => {
  dispatch('clearErrors')
  commit('REQUEST_CALCULATE_PRICE')
  return new Promise((resolve, reject) => {
    api.calculatePrice({
      ...state.form,
      sessiontoken: state.session,
    })
      .then((res) => {
        commit('RECEIVE_CALCULATE_PRICE', res.data)
        resolve(res)
      })
      .catch((err) => {
        commit('RECEIVE_CALCULATE_PRICE', null)
        dispatch('setErrors', err)
        reject(err)
      })
  })
}

/**
 * @param state
 * @param commit
 * @param dispatch
 * @returns {Promise<any>}
 */
export const placeOrder = ({state, commit, dispatch}) => {
  dispatch('clearErrors')
  commit('REQUEST_ORDER')
  return new Promise((resolve, reject) => {
    api.placeOrder({
      ...state.form,
      sessiontoken: state.session,
    })
      .then((res) => {
        commit('RECEIVE_ORDER', res.data)
        resolve(res)
      })
      .catch((err) => {
        commit('RECEIVE_ORDER', null)
        dispatch('setErrors', err)
        reject(err)
      })
  })
}

export const setBillingAddress = ({commit, state, dispatch, getters}) => {
  dispatch('clearErrors')
  commit('REQUEST_ORDER')
  return new Promise((resolve, reject) => {
    api.setBillingAddress({
      sessiontoken: state.session,
      orderId: getters.getOrderId,
      billingAddress: state.form.billingAddress
    }).then(res => {
      commit('RECEIVE_ORDER', {...res.data, containsCheckout: false})
      resolve(res.data)
    }).catch(err => {
      commit('RECEIVE_ORDER', null)
      dispatch('setErrors', err)
      reject(err)
    })
  })
}

/**
 * @param commit
 * @param dispatch
 * @param state
 * @param data
 * @returns {Promise<any>}
 */
export const cancelOrder = ({commit, dispatch, state}, data) => {
  dispatch('clearErrors')
  commit('REQUEST_CANCEL_ORDER')
  return new Promise((resolve, reject) => {
    api.cancelOrder({
      ...data,
      sessiontoken: state.session,
    })
      .then((res) => {
        commit('RECEIVE_CANCEL_ORDER')
        resolve(res)
      })
      .catch((err) => {
        commit('RECEIVE_CANCEL_ORDER')
        dispatch('setErrors', err)
        reject(err)
      })
  })
}

/**
 * @param commit
 * @param dispatch
 * @param state
 * @param data
 * @returns {Promise<any>}
 */
export const confirmOrder = ({commit, dispatch, state}, data) => {
  dispatch('clearErrors')
  commit('REQUEST_ORDER')
  return new Promise((resolve, reject) => {
    api.confirmOrder({
      ...data,
      sessiontoken: state.session,
    })
      .then((res) => {
        commit('RECEIVE_ORDER', res.data)
        resolve(res)
      })
      .catch((err) => {
        commit('RECEIVE_ORDER', {})
        dispatch('setErrors', err)
        reject(err)
      })
  })
}

/**
 * @param commit
 * @param fieldname
 * @param value
 */
export const updateForm = ({ commit }, { fieldname, value }) => {
  commit('UPDATE_FORM', { fieldname, value })
}

/**
 * @param commit
 * @param getters
 */
export const addTuoteRow = ({ commit, getters }) => {
  commit('ADD_TUOTE_ROW', newTuoteRow(getters.nextTuoteRowNumber))
}

/**
 * @param dispatch
 * @param getters
 * @param rowNumber
 * @param fieldname
 * @param value
 */
export const updateTuoteRow = ({ dispatch, getters }, { rowNumber, fieldname, value }) => {
  const rowIndex = getters.findTuoteRowIndex(rowNumber)
  dispatch('updateForm', { fieldname: `tuoteRows.${rowIndex}.${fieldname}`, value })
}

/**
 * @param commit
 * @param rowNumber
 */
export const removeTuoteRow = ({ commit }, rowNumber) => {
  commit('REMOVE_TUOTE_ROW', rowNumber)
}

/**
 * @param commit
 * @param getters
 * @param step
 */
export const goToStep = ({ commit, getters, dispatch }, step) => {
  const stepIndex = getters.findStepIndex(step)
  commit('GO_TO_STEP', stepIndex)
  // Scrollataan toppiin
  dispatch('scrollToTop')
}

/**
 * Scrollaa sivun alkuun
 */
export const scrollToTop = () => {
  const scrollToElement = document.getElementById('top')
  scrollToElement.scrollIntoView()
}

/**
 * @param dispatch
 * @param getters
 */
export const nextStep = ({dispatch, getters}) => {
  const stepIndex = getters.findStepIndex(getters.step)
  dispatch('goToStep', stepIndex + 1)
}

/**
 * @param dispatch
 * @param getters
 */
export const prevStep = ({dispatch, getters}) => {
  const stepIndex = getters.findStepIndex(getters.step)
  dispatch('goToStep', stepIndex - 1)
}

/**
 * @type {{state: *}}
 */
export const changeBillingType = ({ commit }, billingType) => {
  commit('SET_BILLING_TYPE', billingType)
}
