<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    min-width="290px"
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        v-model="formattedDate"
        label="Toivottu toimituspäivä"
        readonly
        v-on="on"
      ></v-text-field>
    </template>
    <v-date-picker
      v-model="date"
      @input="menu = false"
      locale="fi"
      :allowed-dates="allowedDates"
      :first-day-of-week="1"
    ></v-date-picker>
  </v-menu></template>
<script>
import moment from 'moment'
import { mapGetters, mapState, mapActions } from 'vuex'

export default {
  name: 'datepicker',
  data () {
    return {
      menu: false,
    }
  },
  mounted () {
    if (!this.dateIsAllowed(this.date)) {
      this.date = this.getNextAllowedDate
    }
  },
  computed: {
    ...mapState({stateDate: state => state.form.date}),
    ...mapGetters(['getNextAllowedDate', 'dateIsAllowed']),
    date: {
      get () {
        return this.stateDate
      },
      set (value) {
        this.updateForm({fieldname: 'date', value})
      }
    },
    formattedDate () {
      return moment(this.date).format('DD.MM.YYYY')
    }
  },
  methods: {
    ...mapActions(['updateForm']),
    allowedDates (val) {
      return this.dateIsAllowed(val)
    }
  }
}
</script>
