<template>
  <v-select
    class="tuote-select"
    :items="tuoteOptions"
    label="Tuote"
    v-model="tuote"
    item-value="id"
    item-text="name"
    :rules="[v => !!v || 'Pakollinen tieto']"
  ></v-select>
</template>
<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import { get } from 'lodash'

export default {
  name: 'address-from-city-select',
  props: {
    rowNumber: {
      type: Number
    },
  },
  computed: {
    ...mapGetters(['tuoteRow', 'getTuote']),
    ...mapState({
      tuoteEntities: state => state.tuoteEntities
    }),
    tuoteOptions () {
      return this.tuoteEntities.map((tuote) => ({ id: tuote.id, name: tuote.name }))
    },
    tuote: {
      get () {
        return get(this.tuoteRow(this.rowNumber), ['id'], null)
      },
      set (value) {
        this.updateTuoteRow({
          rowNumber: this.rowNumber,
          fieldname: 'id',
          value
        })
        this.$emit('selected', this.getTuote(value))
      }
    }
  },
  methods: {
    ...mapActions(['updateTuoteRow'])
  }
}
</script>
