import Vue from 'vue'
import { get, set, cloneDeep, isNil, uniqBy } from 'lodash'
import initialState from './initialState'
import uuid from 'uuid/v4'

function onMutationCompleted (state) {
  Vue.set(state, 'timestamp', new Date().getTime())
  // Pidetään huoli että meillä on aina sessiontoken
  if (state.session === undefined) {
    Vue.set(state, 'session', uuid())
  }
}

/**
 * @param state
 * @param key
 * @constructor
 */
export function RESET_STATE (state, key) {
  // Resetoidaan kaikki ilman avainta
  if (key === undefined) {
    Object.keys(initialState).forEach((key) => {
      Vue.set(state, key, initialState[key])
    })
  } else {
    // Muutoin resetoidaan vain avaimella
    Vue.set(state, key, initialState[key])
  }
  onMutationCompleted(state)
}

/**
 * @param state
 * @param type
 * @constructor
 */
export function RESET_ADDRESS_STATE (state, type) {
  Vue.set(state.form[type], 'thoroughfare', initialState.form[type].thoroughfare)
  Vue.set(state.form[type], 'streetNumber', initialState.form[type].streetNumber)
  Vue.set(state.form[type], 'postalCode', initialState.form[type].postalCode)
  onMutationCompleted(state)
}

/**
 * @param state
 * @param loadResponse
 * @param pyhapaivaResponse
 * @constructor
 */
export function INIT_APP (state, { loadResponse, pyhapaivaResponse }) {
  Vue.set(state, 'cities', get(loadResponse, 'cities', []))
  Vue.set(state, 'tuoteEntities', get(loadResponse, 'tuoteEntities', []))
  Vue.set(state, 'allowToday', get(loadResponse, 'allowToday', false))
  Vue.set(state, 'pyhapaivat', pyhapaivaResponse)
  onMutationCompleted(state)
}

/**
 * @param state
 * @param errors
 * @constructor
 */
export function SET_ERRORS (state, errors) {
  Vue.set(state, 'errors', errors)
  onMutationCompleted(state)
}

/**
 * @param state
 * @param fieldname
 * @param value
 * @constructor
 */
export function UPDATE_FORM (state, { fieldname, value }) {
  // Kopioidaan koko form ja asetetaan arvo siihen
  let modifiedState = get(state, 'form', {})
  set(modifiedState, fieldname, value)
  // Aseteteaan muokattu form
  Vue.set(state, 'form', modifiedState)
  onMutationCompleted(state)
}

/**
 * @param state
 * @param newTuoteRow
 * @constructor
 */
export function ADD_TUOTE_ROW (state, newTuoteRow) {
  // Kopioidaan tuotteet ja asetetaan arvo siihen
  const modifiedState = [
    ...get(state, ['form', 'tuoteRows'], []),
    newTuoteRow
  ]
  // Asetetaan muokattu form
  Vue.set(state.form, 'tuoteRows', modifiedState)
  Vue.set(state.form, 'lastTuoteRowNumber', newTuoteRow.rowNumber)
  onMutationCompleted(state)
}

/**
 * @param state
 * @param rowNumber
 * @constructor
 */
export function REMOVE_TUOTE_ROW (state, rowNumber) {
  // Kopioidaan tuotteet ja poistetaan arvo
  const modifiedState = get(state, ['form', 'tuoteRows'], [])
    .filter((row) => row.rowNumber !== rowNumber)
  // Asetetaan muokattu form
  Vue.set(state.form, 'tuoteRows', modifiedState)
  onMutationCompleted(state)
}

/**
 * @param state
 * @constructor
 */
export function REQUEST_PYHAPAIVAT (state) {
  Vue.set(state.pyhapaivat, 'isPending', true)
  onMutationCompleted(state)
}

/**
 * @param state
 * @param data
 * @constructor
 */
export function RECEIVE_PYHAPAIVAT (state, data) {
  // Lisätään pyhäpäivät olemassaolevaan dataan
  const modifiedState = {
    ...get(state, ['pyhapaivat', 'data'], {}),
    data
  }
  Vue.set(state.pyhapaivat, 'data', modifiedState)
  Vue.set(state.pyhapaivat, 'isPending', false)
  onMutationCompleted(state)
}

/**
 * @param state
 * @param data
 * @constructor
 */
export function RECEIVE_AUTOCOMPLETE_RESULTS (state, { key, results }) {
  // Kopioidaan koko autocompleteResults ja asetetaan arvo siihen
  let modifiedState = get(state, 'autocompleteResults', {})
  // Poistetaan duplikaatit välissä
  const noDuplicates = uniqBy(results, (result) => result.main_text)
  set(modifiedState, key, noDuplicates)
  // Aseteteaan muokattu autocompleteResults
  Vue.set(state, 'autocompleteResults', modifiedState)
  onMutationCompleted(state)
}

/**
 * @param state
 * @param data
 * @constructor
 */
export function RESET_AUTOCOMPLETE_RESULTS (state, { key }) {
  Vue.set(state.autocompleteResults, key, [])
  onMutationCompleted(state)
}

/**
 * @param state
 * @constructor
 */
export function REQUEST_CALCULATE_PRICE (state) {
  Vue.set(state.price, 'isPending', true)
  onMutationCompleted(state)
}

/**
 * @param state
 * @param data
 * @constructor
 */
export function RECEIVE_CALCULATE_PRICE (state, data) {
  if (isNil(data)) {
    Vue.set(state, 'price', cloneDeep(initialState.price))
  }
  else {
    Vue.set(state.price, 'price', get(data, 'price', 0))
    Vue.set(state.price, 'price_tax', get(data, 'price_tax', 0))
    Vue.set(state.price, 'price_total', get(data, 'price_total', 0))
    Vue.set(state.price, 'parts', get(data, 'parts', []))
    Vue.set(state.price, 'isPending', false)
  }
  onMutationCompleted(state)
}

/**
 * @param state
 * @param index
 * @constructor
 */
export function GO_TO_STEP (state, index) {
  const step = state.stepper.steps[index]
  if (step !== undefined) {
    Vue.set(state.stepper, 'step', step)
  }
  onMutationCompleted(state)
}

/**
 * @param state
 * @param billingType
 * @constructor
 */
export function SET_BILLING_TYPE (state, billingType) {
  Vue.set(state.form, 'billingType', billingType)
  onMutationCompleted(state)
}

/**
 * @param state
 * @constructor
 */
export function REQUEST_ORDER (state) {
  Vue.set(state.order, 'isPending', true)
  onMutationCompleted(state)
}

/**
 * @param state
 * @param data
 * @constructor
 */
export function RECEIVE_ORDER (state, data) {
  Vue.set(state.order, 'isPending', false)
  Vue.set(state.order, 'data', get(data, 'order', null))
  // Ei kosketa checkout-tietoihin, jos esim. actions/setBillingAddress
  const containsCheckout = get(data, 'containsCheckout', true)
  if (containsCheckout) {
    Vue.set(state.checkout, 'providers', get(data, 'checkout.providers', []))
    Vue.set(state.checkout, 'terms', get(data, 'checkout.terms', ''))
  }
  onMutationCompleted(state)
}

/**
 * @param state
 * @constructor
 */
export function REQUEST_CANCEL_ORDER (state) {
  Vue.set(state.order, 'isPending', true)
  onMutationCompleted(state)
}

/**
 * @param state
 * @constructor
 */
export function RECEIVE_CANCEL_ORDER (state) {
  Vue.set(state, 'order', cloneDeep(initialState.order))
  Vue.set(state, 'checkout', cloneDeep(initialState.checkout))
  onMutationCompleted(state)
}
