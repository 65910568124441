import moment from 'moment'
import { get, isNumber, isString, isNil } from 'lodash'

function printDecimal (number) {
  if (Number.isNaN(number)) {
    number = 0;
  }
  return parseFloat(Math.round(number * 100) / 100)
    .toFixed(2)
    .replace('.', ',')
    ;
}

/**
 * @param state
 * @returns {boolean}
 */
export function appLoaded (state) {
  return (state.cities.length > 0) &&
    (state.tuoteEntities.length > 0)
}

/**
 * @param state
 * @returns {{unregister: any; register: any} | default.form | {isValid, from, to, lastTuoteRowNumber, tuoteRows, customer} | HTMLFormElement}
 */
export function form (state) {
  return state.form
}

/**
 * @param state
 * @returns {Function}
 */
export function getTuote (state) {
  return (id) => {
    const tuoteEntities = state.tuoteEntities.filter(tuote => {
      return tuote.id === id
    })
    if (tuoteEntities.length > 0) {
      return tuoteEntities[0]
    }
    return null
  }
}

/**
 * @param state
 * @returns {function(*): number}
 */
export function findTuoteRowIndex (state) {
  return (rowNumber) => {
    return state.form.tuoteRows.findIndex((row) => {
      return row.rowNumber === rowNumber
    })
  }
}

export function tuoteRows (state, getters) {
  return state.form.tuoteRows.map((row) => {
    const tuoteId = get(row, ['id'], null)
    row.tuote = null
    if (tuoteId !== null) {
      row.tuote = getters.getTuote(tuoteId)
    }
    return row
  })
}

/**
 * @param state
 * @param getters
 * @returns {function(*=): *}
 */
export function tuoteRow (state, getters) {
  return (rowNumber) => {
    const index = getters.findTuoteRowIndex(rowNumber)
    return getters.tuoteRows[index]
  }
}

/**
 * @param state
 * @returns {*}
 */
export function nextTuoteRowNumber (state) {
  return state.form.lastTuoteRowNumber + 1
}

/**
 * @param state
 * @returns {Function}
 */
export function dateIsPyha (state) {
  // date muodossa YYYY-MM-DD
  return (date) => {
    const month = date.substr(0, 'YYYY-MM'.length)
    const monthPyhat = state.pyhapaivat[month]
    if (monthPyhat === undefined) {
      return false
    }
    return monthPyhat.some((pyha) => pyha['date'] === date)
  }
}

/**
 * @param state
 * @param getters
 * @returns {Function}
 */
export function dateIsAllowed (state, getters) {
  // date muodossa YYYY-MM-DD
  return (date) => {
    const now = moment()
    const momentDate = moment(date);
    // Ei voi tilata invalidia
    if (!momentDate.isValid()) {
      return false
    }
    // Ei voi tilata menneisyydessä
    if (momentDate.isBefore(now, 'day')) {
      return false
    }
    // Ei voi tilata yli vuoden päähän
    if (momentDate.isAfter(moment().add(1, 'year'), 'day')) {
      return false
    }
    // Ei voi tilata samalle päivälle jos serverin kello on yli x-määrän
    if (momentDate.isSame(now, 'day') && !state.allowToday) {
      return false
    }
    // Ei voi tilata viikonloppuisin
    const weekday = momentDate.day()
    if (weekday === 0 || weekday === 6) {
      return false
    }
    // Ei voi tilata pyhäpäivinä
    if (getters.dateIsPyha(date)) {
      return false
    }
    return true
  }
}

/**
 * @param state
 * @param getters
 * @returns {string}
 */
export function getNextAllowedDate (state, getters) {
  // Maksimikierrokset
  const maxLoops = 100
  // Kutsutaan dateIsAllowed-getteriä kunnes löytyy tai maxLoops täyttyy
  const momentDate = moment()
  for (let i = 0; i < maxLoops; i++) {
    let date = momentDate.format('YYYY-MM-DD')
    if (getters.dateIsAllowed(date)) {
      return date
    }
    momentDate.add(1, 'day')
  }
  // Jos ei löytynyt, niin palautetaan kuitenkin tämä päivä
  // jotta saadaan edes joku arvo pickeriin
  return moment().format('YYYY-MM-DD')
}

/**
 * @param state
 * @returns {string}
 */
export function step (state) {
  return state.stepper.step
}

/**
 * @param state
 * @returns {Function}
 */
export function findStepIndex (state) {
  return (stepIdentifier) => {
    if (isNumber(stepIdentifier)) {
      return stepIdentifier
    }
    if (isString(stepIdentifier)) {
      return state.stepper.steps.findIndex((step) => {
        return step === stepIdentifier
      })
    }
    return 0
  }
}

/**
 * @param state
 * @returns {*}
 */
export function checkoutProviders (state) {
  return get(state, ['checkout', 'providers'], [])
}

/**
 * @param state
 * @returns {*}
 */
export function checkoutTerms (state) {
  return get(state, ['checkout', 'terms'], '')
}

/**
 * @param state
 * @returns {function(*): *}
 */
export function getAutocompleteResults (state) {
  return (key) => {
    return get(
      state,
      ['autocompleteResults', key],
      []
    )
  }
}

export function getTilaus (state) {
  return state.order.data
}

export function getOrderId (state, getters) {
  return get(getters.getTilaus, 'id', null)
}

export function getLahetykset (state, getters) {
  return get(getters.getTilaus, 'lahetykset', [])
}

export function getAsiakas (state, getters) {
  return get(getters.getTilaus, ['asiakas', 0])
}

export function getKayntiosoitePrint (state, getters) {
  let osoite = get(getters.getAsiakas, ['kayntiosoite', 0])
  // Täytetään tarvittavat kentät form-datalla, jos ei tilausta
  if (isNil(getters.getTilaus)) {
    osoite = {
      field_osoite_1: state.form.customer.thoroughfare,
      field_postinumero: state.form.customer.postalCode,
      field_postitoimipaikka: state.form.customer.city,
    }
  }
  return [
    get(osoite, 'field_osoite_1', '-'),
    get(osoite, 'field_postinumero', '-'),
    get(osoite, 'field_postitoimipaikka', '-'),
  ].join(', ')
}

export function getNoutoOsoitePrint (state, getters) {
  let lahetys = get(getters.getLahetykset, '0')
  // Täytetään tarvittavat kentät form-datalla, jos ei tilausta
  if (isNil(getters.getTilaus)) {
    lahetys = {
      nouto_osoite1: state.form.from.thoroughfare + ' ' + state.form.from.streetNumber,
      nouto_postinro: state.form.from.postalCode,
      nouto_postitmp: state.form.from.city,
    }
  }
  return [
    get(lahetys, 'nouto_osoite1', '-'),
    get(lahetys, 'nouto_postinro', '-'),
    get(lahetys, 'nouto_postitmp', '-'),
  ].join(', ')
}

export function getToimitusOsoitePrint (state, getters) {
  let lahetys = get(getters.getLahetykset, '0')
  // Täytetään tarvittavat kentät form-datalla, jos ei tilausta
  if (isNil(getters.getTilaus)) {
    lahetys = {
      toimitus_osoite1: state.form.to.thoroughfare + ' ' + state.form.to.streetNumber,
      toimitus_postinro: state.form.to.postalCode,
      toimitus_postitmp: state.form.to.city,
    }
  }
  return [
    get(lahetys, 'toimitus_osoite1', '-'),
    get(lahetys, 'toimitus_postinro', '-'),
    get(lahetys, 'toimitus_postitmp', '-'),
  ].join(', ')
}

export function getTuotePrints (state, getters) {
  let prints = getters.getLahetykset.map((lahetys) => ({
    name: lahetys.tuote[0].name,
    pituus: printDecimal(lahetys.pituus/10),
    leveys: printDecimal(lahetys.leveys/10),
    korkeus: printDecimal(lahetys.korkeus/10),
    paino: printDecimal(lahetys.yksikkopaino),
    kokonaispaino: printDecimal(lahetys.paino),
    maara: lahetys.maara,
    kuvaus: lahetys.kuvaus,
  }))
  if (isNil(getters.getTilaus)) {
    prints = getters.tuoteRows.map((row) => {
      const kokonaispaino = get(row, 'paino', 0) *
        get(row, 'maara', 1)
      return {
        name: get(row, 'tuote.name', ''),
        pituus: printDecimal(get(row, 'pituus', 0) / 10),
        leveys: printDecimal(get(row, 'leveys', 0) / 10),
        korkeus: printDecimal(get(row, 'korkeus', 0) / 10),
        paino: printDecimal(get(row, 'paino', 0)),
        kokonaispaino: printDecimal(kokonaispaino),
        maara: get(row, 'maara', 1),
        kuvaus: get(row, 'kuvaus', 1),
      }
    })
  }
  return prints
}

export function getYhteyshenkiloPrint (state, getters) {
  const yht = get(getters.getAsiakas, 'yhteyshenkilo', [])
    .filter((yh) => yh.tyyppi === 'asiakas')
  let yh = get(yht, '0')
  // Täytetään tarvittavat kentät form-datalla, jos ei tilausta
  if (isNil(getters.getTilaus)) {
    yh = {
      etunimi: state.form.customer.firstname,
      sukunimi: state.form.customer.lastname,
      email: state.form.customer.email,
      puhelin: state.form.customer.phone
    }
  }
  return {
    etunimi: get(yh, 'etunimi', '-'),
    sukunimi: get(yh, 'sukunimi', '-'),
    email: get(yh, 'email', '-'),
    puhelin: get(yh, 'puhelin', '-'),
  }
}

export function getLaskutustiedotPrint (state, getters) {
  const laskutusosoiteBundle = getters.getLaskutusosoiteBundle
  let laskutustiedot = []
  if (laskutusosoiteBundle === 'laskutusosoite_email') {
    const email = get(state, ['order', 'data', 'laskutus_email'])
    if (email) {
      laskutustiedot.push(email)
    }
  }
  if (laskutusosoiteBundle === 'laskutusosoite_verkkolasku') {
    const verkkolaskuosoite = get(state, ['order', 'data', 'laskutus_verkkolaskuosoite'])
    if (verkkolaskuosoite) {
      laskutustiedot.push(verkkolaskuosoite)
    }
    const valittaja = get(state, ['order', 'data', 'laskutus_valittaja'])
    if (valittaja) {
      laskutustiedot.push(valittaja)
    }
  }
  if (laskutusosoiteBundle === 'laskutusosoite_posti') {
    const osoite1 = get(state, ['order', 'data', 'laskutus_osoite1'])
    if (osoite1) {
      laskutustiedot.push(osoite1)
    }
    const postinro = get(state, ['order', 'data', 'laskutus_postinro'])
    if (postinro) {
      laskutustiedot.push(postinro)
    }
    const postitmp = get(state, ['order', 'data', 'laskutus_postitmp'])
    if (postitmp) {
      laskutustiedot.push(postitmp)
    }
    // const maa = get(state, ['order', 'data', 'laskutus_maa'])
    // if (maa) {
    //   laskutustiedot.push(maa)
    // }
  }
  return laskutustiedot
}

export function getLaskutusosoiteBundle (state) {
  return get(state, ['order', 'data', 'laskutus_tyyppi'])
}

/**
 * @param state
 * @returns {any}
 */
export function billingType (state) {
  return state.form.billingType
}

/**
 * @param state
 * @returns {boolean}
 */
export function isCompany () {
  return true
  // TODO: Checkout ei ole enää päällä
  // const customer = state.form.customer
  // return !isEmpty(customer.company) || !isEmpty(customer.businessId)
}