<template>
  <v-container>
    <spinner v-if="isPending"></spinner>
    <v-row v-else class="mt-8 justify-center">
      <v-col>
        <h3 class="text-center">Maksutapahtuma keskeytyi ja tilaus peruttiin.</h3>
      </v-col>

      <v-col cols="12 text-center">
        <button-custom label="Tilaa uudelleen" @click="newOrder"/>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapActions } from 'vuex'
import Spinner from '@/components/Spinner'
import ButtonCustom from '@/components/ButtonCustom'
export default {
  name: 'page-order-canceled',
  components: {
    ButtonCustom,
    Spinner
  },
  data() {
    const { orderId, paymentId } = this.$route.params
    return {
      isPending: false,
      orderId,
      paymentId,
      checkout: this.$route.query
    }
  },
  mounted () {
    this.scrollToTop()

    // Huudetaan analyticsille
    if (typeof mek_track === 'function') {
      // eslint-disable-next-line no-undef
      mek_track('rahtilaskuri', "'Tilaus peruttu'-sivu ladattu")
    }

    this.isPending = true
    this.cancelOrder({
      orderId: this.orderId,
      paymentId: this.paymentId,
      checkout: this.checkout
    })
      .then(() => this.isPending = false)
      .catch(() => this.isPending = false)
  },
  methods: {
    ...mapActions(['cancelOrder', 'goToStep', 'scrollToTop']),
    newOrder () {
      this.goToStep('address')
      this.$router.replace({name: 'calculator'})
    }
  }
}
</script>
